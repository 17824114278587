import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TopHeader from '../components/layouts/TopHeader'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLoggedUserData } from '../services/auth'
import axios from '../services/axios'
import { editProfileSchema } from '../services/validations'
import "./css/EditProfile.css"
import DefaultProfile from "../assets/DefaultProfile.webp"
import { AiFillEdit } from "react-icons/ai"
import Compress from "compress.js"

const EditProfile = () => {
	const [profileInfo, setProfileInfo] = useState(null)
	const [selectedImage, setSelectedImage] = useState(null)

	const compress = new Compress()

	const token = localStorage.getItem("token")

	const getProfile = async () => {
		const loggedUserData = await getLoggedUserData()
		const { data, status } = await axios.get(
			`Author/view/${loggedUserData._id}`,
		)
		if (status === 200 || status === 201) {
			setProfileInfo(data)
		}
	}
	useEffect(() => {
		getProfile()
	}, [])

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(editProfileSchema),
	})
	const navigate = useNavigate()
	const onSubmit = async formData => {
		const loggedUserData = await getLoggedUserData()
		const { status } = await axios.patch(
			`Author/update/${loggedUserData._id}`,
			formData,
		)
		if (status === 200 || status === 201) {
			toast.success('Profile updated successfully')
			navigate('/profile')
		}
	}

	const updateProfileImage = async () => {
		const compressed = await compress.compress([selectedImage], {
			size: 1,
			quality: 0.65,
			maxWidth: 1920,
			maxHeight: 1920,
			resize: true,
			rotate: false,
		})

		const encodedimage = compressed[0].prefix + compressed[0].data

		let formData = new FormData()

		formData.append('token', token)
		formData.append('encodedimage', encodedimage)

		const { data, status } = await axios.post("Author/profile-image", formData)

		if (data.success) {
			getProfile()
		}
	}

	useEffect(() => {
		if (selectedImage) {
			updateProfileImage()
		}
	}, [selectedImage])

	console.log({ profileInfo })

	return (
		<>
			<div className="_5tqw login-bg">
				<div className="">
					<TopHeader />
				</div>
				{profileInfo && (
					<Container>
						<div className="login-card-wrapper">
							<div className="login-card">
								<div className="login-card-content">
									<h2>Edit Profile</h2>
									<p>Lorem Ipsum is simply dummy text of the printing</p>
								</div>
								<div className="rostrum-hay">
									<label htmlFor="profile-pic">
										<div className="profile-pic">
											<img src={profileInfo.profile_pic ? profileInfo.profile_pic : DefaultProfile} alt="" />
											<AiFillEdit />
										</div>
									</label>
									<input
										id="profile-pic"
										type="file"
										name="file"
										onChange={event => setSelectedImage(event.target.files[0])}
									/>
								</div>
								<div className="login-card-form">
									<form onSubmit={handleSubmit(onSubmit)}>
										<ul>
											<li>
												<div className="field-wrapper">
													<div className="app-form-field">
														<input
															type={'text'}
															placeholder="Enter your Name"
															{...register('first_name', {
																value: profileInfo?.first_name,
															})}
														/>
														<img
															className="input-append-left email-icon"
															src="/assets/images/user.svg"
														/>
													</div>
													{errors.first_name && (
														<div className="error-message">
															{errors.first_name.message}
														</div>
													)}
												</div>
											</li>
											<li>
												<div className="field-wrapper">
													<div className="app-form-field">
														<input
															type={'email'}
															placeholder="Enter your Email"
															{...register('email', {
																value: profileInfo?.email,
															})}
														/>
														<img
															className="input-append-left email-icon"
															src="/assets/images/email.svg"
														/>
													</div>
													{errors.email && (
														<div className="error-message">
															{errors.email.message}
														</div>
													)}
												</div>
											</li>
											<li>
												<div className="field-wrapper">
													<div className="app-form-field">
														<input
															type={'number'}
															placeholder="Enter Phone Number"
															{...register('phonenumber', {
																value: profileInfo?.phonenumber,
															})}
														/>
														<img
															className="input-append-left email-icon"
															src="/assets/images/telephone.svg"
														/>
													</div>
													{errors.phonenumber && (
														<div className="error-message">
															{errors.phonenumber.message}
														</div>
													)}
												</div>
											</li>
											<li>
												<div className="app-form-button">
													<Button
														type="submit"
														color="primary"
														variant="contained"
													>
														Save
													</Button>
												</div>
											</li>
										</ul>
									</form>
									<div className="login-card-form-bottom">
										<Link to="/change-password">
											<span className="app-forgot-pass-text">
												Change Password
											</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Container>
				)}
			</div>
		</>
	)
}
export default EditProfile
