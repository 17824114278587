import DatePicker, {
  utils,
} from '@hassanmojab/react-modern-calendar-datepicker';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import * as React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  formatDateDDDMMM,
  stringDateToRangeObject,
} from '../utilities/DateFormat';
import '../styles/components/room-search.scss';
import moment from "moment/moment";

const RoomSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorE, setAnchorE] = React.useState(null);
  const opened = Boolean(anchorE);

  const handleClick = event => {
    setAnchorE(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE(null);
  };

  const [adultsNumber, setAdultsNumber] = useState(
    searchParams.get('adults') !== "null" ? Number(searchParams.get('adults')) : 1,
  );
  const [childrensNumber, setChildrensNumber] = useState(
    searchParams.get('childrens') !== "null" ? Number(searchParams.get('childrens')) : 0,
  );
  const [roomsNumber, setRoomsNumber] = useState(
    searchParams.get('rooms') !== "null" ? Number(searchParams.get('rooms')) : 1,
  );

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: searchParams.get('from') === "null"
      ? null
      : stringDateToRangeObject(searchParams.get('from')),
    to: searchParams.get('to') === "null"
      ? null
      : stringDateToRangeObject(searchParams.get('to')),
  })

  const formatInputValue = () => {
    if (!selectedDayRange.from || !selectedDayRange.to) return '';

    return `${formatDateDDDMMM(selectedDayRange.from)} - ${formatDateDDDMMM(
      selectedDayRange.to,
    )}`;
  };

  const onSubmit = event => {
    event.preventDefault();
    setSearchParams(searchParams => {
      searchParams.set(
        'from',
        selectedDayRange.from
          ? `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`
          : '',
      );
      searchParams.set(
        'to',
        selectedDayRange.to
          ? `${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
          : '',
      );
      searchParams.set('adults', adultsNumber.toString());
      searchParams.set('childrens', childrensNumber.toString());
      searchParams.set('rooms', roomsNumber.toString());
      searchParams.delete("selectedRooms")
      return searchParams;
    });
  }

  return (
    <>
      <div className="app-component-roomsearch">
        <form onSubmit={onSubmit}>
          <ul className="app-component-roomsearch-list">
            <li className="app-component-roomsearch-list-item">
              <div className="room-search-component-form-group">
                <h4>Select Dates</h4>
                <div className="search-component-form-field">
                  <DatePicker
                    value={selectedDayRange}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="Select a range"
                    formatInputText={formatInputValue}
                    inputClassName="datepicker-range"
                    shouldHighlightWeekends
                    colorPrimary="#ff6431"
                    colorPrimaryLight="#ffeae4"
                    minimumDate={utils('en').getToday()}
                  />
                  <img
                    className="form-field-icon-append"
                    src="/assets/images/calander-icon.svg"
                  />
                </div>
              </div>
            </li>
            <li className="app-component-roomsearch-list-item">
              <div className="room-search-component-form-group">
                <h4>Guest</h4>
                <div className="search-component-form-field">
                  <Button
                    className="search-component-guest-select"
                    id="basic-button"
                    aria-controls={opened ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={opened ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <span>
                      {' '}
                      {`${adultsNumber} A, ${childrensNumber} C, ${roomsNumber} ${
                        roomsNumber > 1 ? 'Rooms' : 'Room'
                      }`}
                    </span>
                  </Button>
                  <Menu
                    className="search-component-guest-select-menu"
                    id="basic-menu"
                    anchorEl={anchorE}
                    open={opened}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <div className="search-component-guest-select-menu-wrapper">
                      <h4>Popular Searches</h4>
                      <ul className="guest-select-options">
                        <li className="guest-select-option-item">
                          <div className="guest-select-option-content">
                            <h6>No of Guests</h6>
                            <p>Adults 18+ years</p>
                          </div>
                          <div className="guest-select-option-increment">
                            <div className="guest-select-option-button guest-select-option-button-left">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setAdultsNumber(oldValue => {
                                    return oldValue > 0 ? oldValue - 1 : 0;
                                  });
                                }}
                              >
                                -
                              </Button>
                            </div>
                            <div className="guest-select-option-input">
                              <input
                                type={'text'}
                                value={adultsNumber}
                                readOnly
                              />
                            </div>
                            <div className="guest-select-option-button guest-select-option-button-right">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setAdultsNumber(oldValue => {
                                    return oldValue + 1;
                                  });
                                }}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </li>
                        <li className="guest-select-option-item">
                          <div className="guest-select-option-content">
                            <h6>Clildrens</h6>
                            <p>From 0 to 17 years</p>
                          </div>
                          <div className="guest-select-option-increment">
                            <div className="guest-select-option-button guest-select-option-button-left">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setChildrensNumber(oldValue => {
                                    return oldValue > 0 ? oldValue - 1 : 0;
                                  });
                                }}
                              >
                                -
                              </Button>
                            </div>
                            <div className="guest-select-option-input">
                              <input
                                type={'text'}
                                value={childrensNumber}
                                readOnly
                              />
                            </div>
                            <div className="guest-select-option-button guest-select-option-button-right">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setChildrensNumber(oldValue => {
                                    return oldValue + 1;
                                  });
                                }}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </li>
                        <li className="guest-select-option-item">
                          <div className="guest-select-option-content">
                            <h6>No of rooms</h6>
                          </div>
                          <div className="guest-select-option-increment">
                            <div className="guest-select-option-button guest-select-option-button-left">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setRoomsNumber(oldValue => {
                                    return oldValue > 0 ? oldValue - 1 : 0;
                                  });
                                }}
                              >
                                -
                              </Button>
                            </div>
                            <div className="guest-select-option-input">
                              <input
                                type={'text'}
                                value={roomsNumber}
                                readOnly
                              />
                            </div>
                            <div className="guest-select-option-button guest-select-option-button-right">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setRoomsNumber(oldValue => {
                                    return oldValue + 1;
                                  });
                                }}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Menu>
                  <img
                    className="form-field-icon-append"
                    src="/assets/images/people-icon.svg"
                  />
                </div>
              </div>
            </li>
            <li className="app-component-roomsearch-list-item">
              <div className="room-search-component-form-button">
                <Button color="primary" variant="contained" type="submit">
                  Check Availability
                </Button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </>
  );
};
export default RoomSearch;
