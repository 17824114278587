import { Container, Typography } from "@mui/material"
import Banner from "../components/home/Banner"
import TopHeader from "../components/layouts/TopHeader"


const ContactPage = () => {
    return (
        <>
            <div className="app-home-wrapper" style={{position:'relative', height:'100vh'}}>
				<div className="app-home-header">
					<TopHeader />
				</div>
                <div style={{width:'82%', backgroundColor:'white', position:'absolute', zIndex:10, top:'20%', left:'8%', boxShadow:' 0 0 10px', borderRadius:'10px'  }}>
                    <div style={{margin:'60px 80px'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'30px'}}>
                            <Typography variant="h4" fontWeight={600}>
                                Contact Us
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>24hrscheckin@gmail.com</p>
                                <p>Contact - 5555</p>
                                <p>Address :  3A, Main Road, Chand Naik Nagar, Banjara Hills, Hyderabad, Telangana 500081 </p>
                                <p>info@albagardens.com +91-85000 08632</p>
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                        </div>
                    </div>
                </div>
				<div className="app-home-banner">
                    <div className="app-component-banner app-component-banner-wrapper">
                    </div>
				</div>
            </div>
        </>
    )
}

export default ContactPage