import { Container, Typography } from "@mui/material"
import Banner from "../components/home/Banner"
import TopHeader from "../components/layouts/TopHeader"
import Footer from "../components/layouts/Footer"


const CancellationOptions = () => {
    return (
        <>
            <div className="app-home-wrapper" style={{position:'relative', height:'100vh'}}>
				<div className="app-home-header">
					<TopHeader />
				</div>
                <div style={{width:'82%', backgroundColor:'white', position:'absolute', zIndex:10, top:'20%', left:'8%', boxShadow:' 0 0 10px', borderRadius:'10px'  }}>
                    <div style={{margin:'60px 80px'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'30px'}}>
                            <Typography variant="h4" fontWeight={600}>
                                Cancellation Policy
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>You can cancel your booking using the 24hourscheckin.com website or mobile app.</p>
                                <p>The applicable refund amount will be credited to you within 7-14 working days. 24Hours Checkin reserves the right to debit from 24Hours Checkin Money account, in case of cancellation amount being higher than money already paid by you.</p>
                                <p>Some Hotels do not accept bookings from unmarried couples, do not accept local id proofs. This information is available to the Guest prior to making the booking. For any cancellations or check-in denial associated with such bookings that are dishonored by the Hotel, 24Hours Checkin shall be under no obligation to refund any amount to the Guest.</p>
                                <p>Hotels reserve the right to deny check-in where customers are unable to provide a valid government id or where minor Guests are traveling unaccompanied or if the Hotel is suspicious of the Guest's check-in at its Property. Under all such cases 24Hours Checkin shall be under no obligation to refund any amount to the Guest.</p>
                                
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                        </div>
                    </div>
                </div>
				<div className="app-home-banner" style={{marginBottom:'160px'}}>
                    <div className="app-component-banner app-component-banner-wrapper">
                    </div>
				</div>
            </div>
        </>
    )
}

export default CancellationOptions