import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TopHeader from '../components/layouts/TopHeader'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getLoggedUserData } from '../services/auth'
import axios from '../services/axios'
import DefaultProfile from "../assets/DefaultProfile.webp"
import "./css/Profile.css"

const Profile = () => {
	const [profileInfo, setProfileInfo] = useState(null)

	const getProfile = async () => {
		const loggedUserData = await getLoggedUserData()
		const { data, status } = await axios.get(
			`Author/view/${loggedUserData._id}`,
		)
		if (status === 200 || status === 201) {
			setProfileInfo(data)
		}
	}
	useEffect(() => {
		getProfile()
	}, [])
	

	return (
		<>
			<div className="_1ejp login-bg">
				<div className="">
					<TopHeader />
				</div>
				{profileInfo && (
					<Container>
						<div className="login-card-wrapper">
							<div className="login-card app-page-profile-card">
								<div className="app-page-profile-edit">
									<Link to="/edit-profile">
										<Button className="plain-primary-btn">
											<img src="/assets/images/edit.svg" />
											<span>Edit Profile</span>
										</Button>
									</Link>
								</div>
								<div className="login-card-content">
									<h2>My Profile</h2>
									<p>Lorem Ipsum is simply dummy text of the printing</p>
								</div>
								<div className="rostrum-hay">
									<div className="profile-pic">
										<img src={profileInfo.profile_pic ? profileInfo.profile_pic : DefaultProfile} alt="" />
									</div>
								</div>
								<div className="app-page-profile-detail">
									<ul>
										<li>
											<h4>Full Name</h4>
											<p>{`${profileInfo.first_name || ''} ${profileInfo.last_name || ''}`}</p>
											{/* <p>{`${profileInfo?.first_name} ${profileInfo?.last_name}`}</p> */}
											
											
										</li>
										
										<li>
											<h4>Phone Number</h4>
											<p>{profileInfo?.phonenumber}</p>
										</li>
										<li>
											<h4>Email</h4>
											<p>{profileInfo?.email}</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</Container>
				)}
			</div>
		</>
	)
}
export default Profile
