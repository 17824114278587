import Grid from '@mui/material/Grid';
import React from 'react';
import { apiBaseUrl } from '../services/axios';

const PropertyGallery = ({ property }) => {
  return (
    <>
      <div className="app-component-top-places">
        <Grid container spacing={2}>
          {property ? (
            <Grid key={property._id} item xs={6}>
              <div className="app-component-top-places-card">
                <img src={property.banner_image ? (property.banner_image.includes("amazonaws.com") ? property.banner_image : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png") : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png"} />
              </div>
            </Grid>
          ) : undefined}
        </Grid>
      </div>
    </>
  );
};
export default PropertyGallery;
