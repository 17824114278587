import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Container } from '@mui/system'
import TopHeader from '../components/layouts/TopHeader'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLoggedUserData } from '../services/auth'
import axios, { apiBaseUrl } from '../services/axios'
import { getSavedPricePercentage } from '../utilities/Costs'
import {
	formatDateDDDMMMFromString,
	formatDateYYYMMddFromString,
} from '../utilities/DateFormat'
import { MdPending } from "react-icons/md"
import { FaCalendarCheck } from "react-icons/fa"
import { RiVerifiedBadgeFill, RiMoneyPoundCircleFill } from "react-icons/ri"
import { HiClock } from "react-icons/hi2"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

const Bookings = () => {
	const [selectedTab, setSelectedTab] = React.useState(0)
	const [bookings, setBookings] = useState([])
	const [loading, setLoading] = useState(true)
	const [loggedUserData, setLoggedUserData] = useState(undefined)
	const [bookedRooms, setBookedRooms] = useState([])

	const token = localStorage.getItem("token")

	const navigate = useNavigate()

	// @ts-ignore
	window.FreshworksWidget('hide', 'launcher')

	const getBookings = async tabType => {
		setLoading(true)
		const { data, status } = await axios.get(`Booking/list?tabType=${tabType}&author_id=${loggedUserData.id}`)

		console.log({ data })

		if (status === 200 || status === 201) {
			const results = data.results
			const bookings = []

			results.forEach(booking => {
				const hotelrooms = JSON.parse(booking.hotel_infomration)

				const bookedrooms = booking.room_ids.map(room => {
					return {
						...room,
						details: hotelrooms.find(item => item._id === room.hotel_room_id)
					}
				})

				bookings.push({
					...booking,
					bookedrooms
				})
			})

			setBookings(bookings.reverse())
			setLoading(false)
		}
	}

	const authorInfo = async () => {
		const { data, status } = await axios.post("/Author/info", { token })

		if (data.success) {
			setLoggedUserData(data.author)
		}
	}

	useEffect(() => {
		authorInfo()
	}, [])

	useEffect(() => {
		const tabType =
			selectedTab === 0 ? 'Upcoming' : selectedTab === 1 ? 'Past' : 'Cancelled'

		if (loggedUserData) {
			getBookings(tabType)
		}
	}, [selectedTab, loggedUserData])

	const handleTabChange = (_event, newValue) => {
		setBookings([])
		setSelectedTab(newValue)
	}

	const handleBookAgain = (propertyId, from, to, adults, childrens, rooms) => {
		navigate(
			`/properties-inner/${propertyId}?locationId=&locationTitle=&from=${from}&to=${to}&adults=${adults}&childrens=${childrens}&rooms=${rooms}`,
		)
	}

	const handleCancelBooking = async bookingId => {
		const updatebooking = {
			booking_status: 'Cancelled',
		}
		const { status } = await axios.patch(
			`/Booking/update/${bookingId}`,
			updatebooking,
		)
		if (status === 200 || status === 201) {
			toast.success('Booking cancelled successfully')
			const tabType =
				selectedTab === 0
					? 'Upcoming'
					: selectedTab === 1
						? 'Past'
						: 'Cancelled'
			getBookings(tabType)
		}
	}

	const handleSupport = async () => {
		// @ts-ignore
		window.FreshworksWidget('open')
	}

	console.log({ bookings })

	return (
		<>
			<div className="app-page-bookings">
				<div className="app-page-bookings-header">
					<TopHeader />
				</div>
				<div className="app-page-bookings-subheader">
					<Container maxWidth="lg">
						<div className="app-page-bookings-subheader-wrapper">
							<h1 className="bookings-subheader-heading">My Bookings</h1>
							<div className="bookings-subheader-tabs">
								<Tabs
									value={selectedTab}
									onChange={handleTabChange}
									aria-label="basic tabs example"
								>
									<Tab label="Upcoming Bookings" />
									<Tab label="Checkedout Bookings" />
									<Tab label="Cancelled Bookings" />
								</Tabs>
							</div>
						</div>
					</Container>
				</div>
				{loading && (
					<div className="app-loading">
						<CircularProgress color="inherit" />
					</div>
				)}
				{!loading && (
					<div className="app-page-bookings-content">
						<Container maxWidth="lg">
							<TabPanel value={selectedTab} index={0}>
								<div className="app-page-bookings-tab-content">
									<h3 className="bookings-tab-content-heading">
										Upcoming Bookings
									</h3>
									{ bookings.length !== 0 ? 
									<ul className="app-page-bookings-tab-content-list">
										{bookings.reverse().map(booking => {
											const hotelInformation = JSON.parse(
												booking.hotel_infomration,
											)[0]
											return (
												<li
													key={booking._id}
													className="app-page-bookings-tab-content-list-item"
												>
													<div className="app-bookings-card">
														<div className="app-bookings-card-content">
															<div>
																<img
																	style={{ width: "200px", height: "200px", objectFit: "cover" }}
																	className="app-bookings-card-content-image"
																	src={hotelInformation.hotel_id.banner_image}
																/>
															</div>
															<div className="app-bookings-card-info">
																<div className="app-comoponent-property-card-details">
																	<div className="property-card-details-heading">
																		<div className="property-card-details-info">
																			<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
																				<div style={{ fontWeight: 500 }}>{hotelInformation.hotel_id.title}</div>
																				<div style={{ display: "flex", alignItems: "center", gap: "4px", marginTop: "2px" }}>
																					<img style={{ width: "13px", height: "13px", backgroundColor: "grey", padding: "2px", borderRadius: "50%" }} src="/assets/images/star.svg" />
																					<span style={{ fontWeight: 500, fontSize: "12px", color: "grey" }}>
																						{hotelInformation.hotel_id.rating_standard}
																					</span>
																				</div>
																			</div>
																			<div style={{ color: "grey", fontSize: "10px", fontWeight: 400, marginTop: "10px" }}>
																				<FaCalendarCheck />
																				<span style={{ marginLeft: "4px" }}>BOOKING MADE ON</span>
																				<span style={{ marginLeft: "4px" }}>{new Date(booking.confirmed_at).toDateString().toUpperCase()}</span>
																			</div>

																			<p>
																				{hotelInformation.hotel_id.real_address}
																			</p>
																			<h6>{`${formatDateDDDMMMFromString(
																				booking.checkin_date,
																			)} - ${formatDateDDDMMMFromString(
																				booking.checkout_date,
																			)} | ${booking.no_of_adults} ${booking.no_of_adults > 1
																				? 'Adults'
																				: 'Adult'
																				} | ${booking.no_of_chlidrens} ${booking.no_of_chlidrens > 1
																					? 'Childrens'
																					: 'Children'
																				} | ${booking.no_of_rooms} ${booking.no_of_rooms > 1
																					? 'Rooms'
																					: 'Room'
																				}`}</h6>
																		</div>

																	</div>
																	{booking.bookedrooms.map(room => <div style={{ fontSize: "12px", fontWeight: 500, marginTop: "10px" }}>
																		<span>ROOM</span>
																		<span style={{ marginLeft: "4px" }}>{String(room.details.name).toUpperCase()}</span>
																		<span style={{ backgroundColor: "rgb(209, 209, 209)", padding: "0px 6px", marginLeft: "10px", borderRadius: "4px" }}>{room.no_of_rooms}</span>
																	</div>)}
																	<div style={{ fontSize: "14px", marginTop: "8px", color: "grey" }}>{booking.instructions}</div>
																	<div>
																		<p>
																			{hotelInformation.hotel_id.ReviewsCount
																				? `${hotelInformation.hotel_id.ReviewsCount} Reviews`
																				: ''}
																		</p>
																	</div>
																	<div style={{ display: "flex", alignItems: "center", marginTop: "8px", marginBottom: "8px" }}>
																		<span style={{ color: "rgb(40, 40, 40)", fontWeight: 500, fontSize: "14px" }}>Booking Amount</span>
																		<span style={{ color: "rgb(40, 40, 40)", fontSize: "14px", fontWeight: 500, marginLeft: "10px", marginRight: "5px" }}>
																			{`₹ ${booking.discount + booking.total_paid}`}
																		</span>
																	</div>
																	<div style={{ display: "flex", alignItems: "center", marginTop: "8px", marginBottom: "8px" }}>
																		<span style={{ color: "grey", fontWeight: 500, fontSize: "12px" }}>Discount</span>
																		<span style={{ color: "#069e20", fontSize: "12px", fontWeight: 500, marginLeft: "10px", marginRight: "5px" }}>
																			{`₹ ${booking.discount}`}
																		</span>
																		{booking.discount ? <span style={{ color: "white", backgroundColor: "rgb(173, 173, 173)", fontSize: "9px", padding: "3px 7px" }}>
																			{booking.promocode}
																		</span> : undefined}
																	</div>
																	<div className="property-card-details-price">
																		<div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
																			<span style={{ color: "#069e20", fontSize: "14px", marginRight: "10px" }}>{`₹ ${booking.total_paid}`}</span>
																			{booking.payment_method === "At Hotel" ? (
																				<>
																					<div style={{ display: "flex", alignItems: "center", gap: "4px", backgroundColor: "#ff6431", color: "white", padding: "4px 7px", borderRadius: "20px", fontSize: "12px", marginRight: "6px" }}>
																						<HiClock style={{ width: "16px", height: "16px" }} />
																						Pay at Hotel
																					</div>
																					{booking.payment_at_hotel?.status === "Pending" ? (
																						<div style={{ display: "flex", alignItems: "center", gap: "4px", backgroundColor: "rgb(159, 159, 159)", color: "white", padding: "4px 7px", borderRadius: "20px", fontSize: "12px" }}>
																							<RiMoneyPoundCircleFill style={{ width: "16px", height: "16px" }} />
																							Pending
																						</div>
																					) : (
																						<div style={{ display: "flex", alignItems: "center", gap: "4px", backgroundColor: "#069e20", color: "white", padding: "4px 7px", borderRadius: "20px", fontSize: "12px" }}>
																							<RiVerifiedBadgeFill style={{ width: "16px", height: "16px" }} />
																							Paid
																						</div>
																					)}
																				</>
																			) : (
																				<div style={{ display: "flex", alignItems: "center", gap: "4px", backgroundColor: "#069e20", color: "white", padding: "4px 7px", borderRadius: "20px", fontSize: "12px" }}>
																					<RiVerifiedBadgeFill style={{ width: "16px", height: "16px" }} />
																					Paid
																				</div>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="app-bookings-card-buttons">
															<ul>
																<li>
																	<Button
																		className="app-primary-btn-with-icon-large"
																		variant="outlined"
																		onClick={() => {
																			handleCancelBooking(booking._id)
																		}}
																	>
																		<img src="/assets/images/exchange.svg" />
																		<span>Cancel Booking</span>
																	</Button>
																</li>
																<li>
																	<Button
																		className="app-grey-btn-with-icon-large"
																		variant="outlined"
																		onClick={handleSupport}
																	>
																		<img src="/assets/images/customer-support.svg" />
																		<span>Need Help</span>
																	</Button>
																</li>
															</ul>
														</div>
														<div className="app-bookings-card-sticker">
															<p>{`Booking ID: ${booking._id}`}</p>
														</div>
													</div>
												</li>
											)
										})}
									</ul>:
									<div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'300px'}}>
										<h3>No Bookings Available</h3>
									</div>
								}
								</div>
							</TabPanel>
							<TabPanel value={selectedTab} index={1}>
								<div className="app-page-bookings-tab-content">
									<h3 className="bookings-tab-content-heading">
										Checkedout Bookings
									</h3>
									{ bookings.length !== 0 ? 
									<ul className="app-page-bookings-tab-content-list">
										{bookings.map(booking => {
											const hotelInformation = JSON.parse(
												booking.hotel_infomration,
											)[0]
											return (
												<li
													key={booking._id}
													className="app-page-bookings-tab-content-list-item"
												>
													<div className="app-bookings-card">
														<div className="app-bookings-card-content">
															<img
																className="app-bookings-card-content-image"
																src="https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/visualsofdana-T5pL6ciEn-I-unsplash.jpg"
															/>
															<div className="app-bookings-card-info">
																<div className="app-comoponent-property-card-details">
																	<div className="property-card-details-heading">
																		<div className="property-card-details-info">
																			<h4>{hotelInformation.hotel_id.title}</h4>
																			<p>
																				{hotelInformation.hotel_id.real_address}
																			</p>
																			<h6>{`${formatDateDDDMMMFromString(
																				booking.checkin_date,
																			)} - ${formatDateDDDMMMFromString(
																				booking.checkout_date,
																			)} | ${booking.no_of_adults} ${booking.no_of_adults > 1
																				? 'Adults'
																				: 'Adult'
																				} | ${booking.no_of_chlidrens} ${booking.no_of_chlidrens > 1
																					? 'Childrens'
																					: 'Children'
																				} | ${booking.no_of_rooms} ${booking.no_of_rooms > 1
																					? 'Rooms'
																					: 'Room'
																				}`}</h6>
																		</div>
																	</div>
																	<div className="property-card-details-reviews">
																		<div className="property-card-details-reviews-card">
																			<img src="/assets/images/star.svg" />
																			<span>
																				{
																					hotelInformation.hotel_id
																						.rating_standard
																				}
																			</span>
																		</div>
																		<p>
																			{hotelInformation.hotel_id.ReviewsCount
																				? `${hotelInformation.hotel_id.ReviewsCount} Reviews`
																				: ''}
																		</p>
																	</div>
																	<div className="property-card-details-price">
																		<h2>{`₹ ${hotelInformation.hotel_id.price}`}</h2>
																		{hotelInformation.hotel_id.selling_price &&
																			hotelInformation.hotel_id.price !==
																			hotelInformation.hotel_id
																				.selling_price && (
																				<>
																					<h3>{`₹ ${hotelInformation.hotel_id.selling_price}`}</h3>
																					<span>{`${getSavedPricePercentage(
																						hotelInformation.hotel_id.price,
																						hotelInformation.hotel_id
																							.selling_price,
																					)}% Off`}</span>
																				</>
																			)}
																	</div>
																</div>
															</div>
														</div>
														<div className="app-bookings-card-buttons">
															<ul>
																<li>
																	<Button
																		className="app-primary-btn-with-icon"
																		variant="outlined"
																		onClick={() => {
																			const from = booking.checkin_date
																				? formatDateYYYMMddFromString(
																					booking.checkin_date,
																				)
																				: undefined
																			const to = booking.checkout_date
																				? formatDateYYYMMddFromString(
																					booking.checkout_date,
																				)
																				: undefined
																			handleBookAgain(
																				hotelInformation.hotel_id._id,
																				from,
																				to,
																				booking.no_of_adults,
																				booking.no_of_chlidrens,
																				booking.no_of_rooms,
																			)
																		}}
																	>
																		<img src="/assets/images/exchange.svg" />
																		<span>Book again</span>
																	</Button>
																</li>
																<li>
																	<Button
																		className="app-grey-btn-with-icon"
																		variant="outlined"
																		onClick={handleSupport}
																	>
																		<img src="/assets/images/customer-support.svg" />
																		<span>Need Help</span>
																	</Button>
																</li>
															</ul>
														</div>
														<div className="app-bookings-card-sticker">
															<p>{`Booking ID: ${booking._id}`}</p>
														</div>
													</div>
												</li>
											)
										})}
									</ul>:
									<div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'300px'}}>
										<h3>No Bookings Available</h3>
									</div>
								}
								</div>
							</TabPanel>
							<TabPanel value={selectedTab} index={2}>
								<div className="app-page-bookings-tab-content">
									<h3 className="bookings-tab-content-heading">
										Cancelled Bookings
									</h3>
									{ bookings.length !== 0 ? 
									<ul className="app-page-bookings-tab-content-list">
										{bookings.map(booking => {
											const hotelInformation = JSON.parse(
												booking.hotel_infomration,
											)[0]
											return (
												<li
													key={booking._id}
													className="app-page-bookings-tab-content-list-item"
												>
													<div className="app-bookings-card">
														<div className="app-bookings-card-content">
															<img
																className="app-bookings-card-content-image"
																src="/assets/images/manali.jpg"
															/>
															<div className="app-bookings-card-info">
																<div className="app-comoponent-property-card-details">
																	<div className="property-card-details-heading">
																		<div className="property-card-details-info">
																			<h4>{hotelInformation.hotel_id.title}</h4>
																			<p>
																				{hotelInformation.hotel_id.real_address}
																			</p>
																			<h6>{`${formatDateDDDMMMFromString(
																				booking.checkin_date,
																			)} - ${formatDateDDDMMMFromString(
																				booking.checkout_date,
																			)} | ${booking.no_of_adults} ${booking.no_of_adults > 1
																				? 'Adults'
																				: 'Adult'
																				} | ${booking.no_of_chlidrens} ${booking.no_of_chlidrens > 1
																					? 'Childrens'
																					: 'Children'
																				} | ${booking.no_of_rooms} ${booking.no_of_rooms > 1
																					? 'Rooms'
																					: 'Room'
																				}`}</h6>
																		</div>
																	</div>
																	<div className="property-card-details-reviews">
																		<div className="property-card-details-reviews-card">
																			<img src="/assets/images/star.svg" />
																			<span>
																				{
																					hotelInformation.hotel_id
																						.rating_standard
																				}
																			</span>
																		</div>
																		<p>
																			{hotelInformation.hotel_id.ReviewsCount
																				? `${hotelInformation.hotel_id.ReviewsCount} Reviews`
																				: ''}
																		</p>
																	</div>
																	<div className="property-card-details-price">
																		<h2>{`₹ ${hotelInformation.hotel_id.price}`}</h2>
																		{hotelInformation.hotel_id.selling_price &&
																			hotelInformation.hotel_id.price !==
																			hotelInformation.hotel_id
																				.selling_price && (
																				<>
																					<h3>{`₹ ${hotelInformation.hotel_id.selling_price}`}</h3>
																					<span>{`${getSavedPricePercentage(
																						hotelInformation.hotel_id.price,
																						hotelInformation.hotel_id
																							.selling_price,
																					)}% Off`}</span>
																				</>
																			)}
																	</div>
																</div>
															</div>
														</div>
														<div className="app-bookings-card-buttons">
															<ul>
																<li>
																	<Button
																		className="app-grey-btn-with-icon"
																		variant="outlined"
																		onClick={handleSupport}
																	>
																		<img src="/assets/images/customer-support.svg" />
																		<span>Need Help</span>
																	</Button>
																</li>
															</ul>
														</div>
														<div className="app-bookings-card-sticker">
															<p>{`Booking ID: ${booking._id}`}</p>
														</div>
													</div>
												</li>
											)
										})}
									</ul>:
									<div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'300px'}}>
										<h3>No Bookings Available</h3>
									</div>
								}
								</div>
							</TabPanel>
						</Container>
					</div>
				)}
			</div>
		</>
	)
}
export default Bookings
