import axios from "axios"
import { apiBaseUrl } from "./axios"
import { deleteLocalStorageItem, getLocalStorageItem } from './storageHandling'

export const isAuthenticated = () => {
	const token = getLocalStorageItem('token')
	return token && token.trim().length > 0
}

export const getLoggedUserData = async () => {
	const token = getLocalStorageItem("token")

	const { data, status } = await axios.post(`${apiBaseUrl}api/Author/info`, { token })

	if (data.success) {
		return data.author
	}

	return undefined
}

export const logout = () => {
	deleteLocalStorageItem('token')
	deleteLocalStorageItem('refresh_token')
	deleteLocalStorageItem("userinfo")
	deleteLocalStorageItem('logged_user_data')
}
