import axios from 'axios'
import { toast } from 'react-toastify'
import { getLocalStorageItem, setLocalStorageItem } from './storageHandling'

export let apiBaseUrl

if (process.env.NODE_ENV === "development") {
	apiBaseUrl = "http://localhost:5050/"
} else {
	apiBaseUrl = "https://backend.24hourscheckin.com/"
}

const url = `${apiBaseUrl}api/`

const instance = axios.create({
	baseURL: url,
	timeout: 30000,
})

const refreshToken = () => {
	const refreshToken = getLocalStorageItem('refresh_token')
	return instance.get(`/Author/refreshToken/${refreshToken}`)
}

instance.interceptors.response.use(
	res => {
		return res
	},
	async err => {
		const originalConfig = err.config
		if (err.response) {
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true
				try {
					const rs = await refreshToken()
					const { Token } = rs.data
					setLocalStorageItem('token', Token)
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + Token
					return instance(originalConfig)
				} catch (_error) {
					if (_error.response && _error.response.data) {
						return Promise.reject(_error.response.data)
					}
					return Promise.reject(_error)
				}
			} else if (err.response?.status == 404) {
				toast.error('Not authorized')
			} else if (err.response?.status == 400 && err.response?.data?.message) {
				toast.error(err.response?.data?.message)
			} else {
				toast.error('something went wrong')
			}
		}
		return Promise.reject(err)
	},
)

instance.interceptors.request.use(request => {
	const token = getLocalStorageItem('token')
	if (
		token &&
		request.url !== 'Author/login' &&
		request.url !== 'Author/create'
	) {
		request.headers['Authorization'] = `Bearer ${token}`
	}
	return request
})

export default instance
