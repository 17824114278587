import { Container, Typography } from "@mui/material"
import Banner from "../components/home/Banner"
import TopHeader from "../components/layouts/TopHeader"


const AboutPage = () => {
    return (
        <>
            <div className="app-home-wrapper" style={{position:'relative', height:'100vh'}}>
				<div className="app-home-header">
					<TopHeader />
				</div>
                <div style={{width:'82%', backgroundColor:'white', position:'absolute', zIndex:10, top:'20%', left:'8%', boxShadow:' 0 0 10px', borderRadius:'10px'  }}>
                    <div style={{margin:'60px 80px'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'30px'}}>
                            <Typography variant="h4" fontWeight={600}>
                                About Us
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>Welcome to 24hourscheckin.com – your gateway to hassle-free hotel bookings and extended check-in times!</p>
                                <p>At 24hourscheckin.com, we understand the frustration of having to wait until the standard check-in time to access your hotel room, especially after a long journey or an early arrival. That's why we're on a mission to revolutionize the hospitality industry by offering our customers the flexibility to check in at any time of the day or night.</p>
                                <p>Backed by our parent company, Tripizi Trot Private Limited, we've partnered with a network of hotels that share our vision of putting customer convenience first. By leveraging innovative technologies and streamlining processes, we've made it possible for you to seamlessly book and access your room whenever you arrive, without the need to wait or pay extra fees.</p>
                                <p>But our commitment goes beyond just extending check-in times. We also strive to enhance the profitability and occupancy rates for our partner hotels. By optimizing room availability and maximizing their revenue streams, we empower them to provide exceptional service while maintaining a sustainable business model.</p>
                                <p>At the core of our platform lies a deep understanding of the travel industry and a genuine passion for creating memorable experiences. Our team of dedicated professionals works tirelessly to curate a selection of top-notch hotels, negotiate advantageous rates, and ensure a seamless booking process for our customers.</p>
                                <p>We believe that travel should be a delightful journey from start to finish, and that's why we've infused a human touch into every aspect of our operations. From our user-friendly website to our responsive customer support, we strive to make your experience with us as comfortable and enjoyable as possible.</p>
                                <p>Join us on this exciting journey as we reshape the hotel industry, one check-in at a time. Explore our website, discover our carefully curated selection of hotels, and experience the convenience of 24-hour check-in like never before.</p>
                                <p>Welcome to the future of hassle-free travel – welcome to 24hourscheckin.com!</p>

                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                        </div>
                    </div>
                </div>
				<div className="app-home-banner">
                    <div className="app-component-banner app-component-banner-wrapper">
                    </div>
				</div>
            </div>
        </>
    )
}

export default AboutPage