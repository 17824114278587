import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	IconButton,
	Popover,
	Tooltip,
} from '@mui/material'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLoggedUserData } from '../services/auth'
import axios from '../services/axios'
import moment from "moment"

import CouponCard from './CouponCard'

const validateBooking = (from, to, adults, childrens, rooms) => {
	let validate = true
	if (!from || !to) {
		toast.error('Please, select date range')
		validate = false
	}
	if (!adults || adults === 0) {
		toast.error('Please, select guest number')
		validate = false
	}
	if (rooms === 0) {
		toast.error('Please, select any room')
		validate = false
	}
	return validate
}

const BookingDetails = ({
	property,
	totalPrice,
	setTotalPrice,
	totalPriceWithoutDiscount,
	numberOfDays,
	selectedRooms,
}) => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const adults = searchParams.get('adults')
		? Number(searchParams.get('adults'))
		: 0
	const childrens = searchParams.get('childrens')
		? Number(searchParams.get('childrens'))
		: 0

	const from = searchParams.get('from')
	const to = searchParams.get('to')

	const [openmodal, setOpenmodal] = useState(false)
	const [showAppliedCoupon, setShowAppliedCoupon] = useState(false)
	const handleOpenModal = () => setOpenmodal(true)
	const handleCloseModal = () => setOpenmodal(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [selectedExtraPrices, setSelectedExtraPrices] = useState([])
	const [totalExtraPrices, setTotalExtraPrices] = useState(0)
	const [totalBuyerPrices, setTotalBuyerPrices] = useState(0)
	const [coupons, setCoupons] = useState([])
	const [promoCode, setPromoCode] = useState('')
	const [appliedCoupon, setAppliedCoupon] = useState(null)
	const [appliedDiscount, setAppliedDiscount] = useState(0)
	const [loading, setLoading] = useState(false)

	const open = Boolean(anchorEl)

	const token = localStorage.getItem("token")

	const selectedRoomsWithNumber = selectedRooms.filter(
		selectedRoom => selectedRoom.number > 0,
	)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleExtraPriceChange = event => {
		let selectedExtraPriceIds = []
		setSelectedExtraPrices(oldExtraPrices => {
			if (event.target.checked) {
				const extraPriceExist = oldExtraPrices?.find(
					extraPrice => extraPrice._id === event.target.value,
				)
				if (!extraPriceExist) {
					selectedExtraPriceIds = [...oldExtraPrices?.map(extraPrice => extraPrice._id), event.target.value]
				}
			} else {
				selectedExtraPriceIds = oldExtraPrices?.filter(
					extraPrice => extraPrice._id !== event.target.value,
				).map(extraPrice => extraPrice._id)
			}
			let newCompletedExtraPrices = []
			selectedExtraPriceIds.forEach(extraPriceId => {
				const completeExtraPrice = property?.HotelExtraPrices.find(
					hotelExtraPrice => hotelExtraPrice._id === extraPriceId,
				)
				newCompletedExtraPrices.push(completeExtraPrice)
			})
			return newCompletedExtraPrices
		})
	}

	const getCoupons = async () => {
		const { data, status } = await axios.post(`Coupon/hotel-list`, {
			token,
			hotelid: property._id
		})

		if (data.success) {
			setCoupons(data.coupons)
		}
	}

	useEffect(() => {
		getCoupons()
	}, [])

	const calculateTotalExtraPrices = () => {
		const sumExtraPrices = selectedExtraPrices.reduce(
			(accumulator, selectedExtraPrice) => {
				console.log({ selectedExtraPrice })
				if (selectedExtraPrice.is_per_person) {
					return accumulator + selectedExtraPrice.price * (adults + childrens)
				} else {
					return accumulator + selectedExtraPrice.price
				}
			},
			0,
		)
		setTotalExtraPrices(sumExtraPrices)
	}

	console.log({ totalExtraPrices })

	useEffect(() => {
		calculateTotalExtraPrices()
	}, [selectedExtraPrices, adults, childrens])

	const calculateTotalBuyerPrices = () => {
		const sumBuyerPrices = property?.HotelBuyerPrices.reduce(
			(accumulator, buyerPrice) => {
				if (buyerPrice.is_price_per_person) {
					return accumulator + buyerPrice.price * (adults + childrens)
				} else {
					return accumulator + buyerPrice.price
				}
			},
			0,
		)
		setTotalBuyerPrices(sumBuyerPrices)
	}

	useEffect(() => {
		calculateTotalBuyerPrices()
	}, [property?.HotelBuyerPrices, adults, childrens])

	const recalculateTotalPrice = () => {
		const discount = appliedCoupon.ispercentage ? totalPriceWithoutDiscount * appliedCoupon.discount / 100 : appliedCoupon.discount

		setAppliedDiscount(discount)
		setTotalPrice(totalPriceWithoutDiscount - discount)
	}

	useEffect(() => {
		if (appliedCoupon) {
			recalculateTotalPrice()
		} else {
			setTotalPrice(totalPriceWithoutDiscount)
		}
	}, [appliedCoupon])

	const onSubmitCoupon = async event => {
		event.preventDefault()
		const { data, status } = await axios.get(
			`/Coupon/CheckCoupon/${promoCode}/${totalPrice}?no_of_guests=${adults}&no_of_childrens=${childrens}`,
		)

		if (status === 200 || status === 201) {
			console.log(1)
			if (data?.isValid) {
				console.log(2)
				if (!appliedCoupon) {
					const coupon = coupons.find(coupon => coupon.code === promoCode)

					console.log({ totalPrice, t: data.discount })

					setAppliedCoupon({
						id: coupon._id,
						code: coupon.code,
						discount: data?.discount,
					})
					toast.success(data?.message)
				} else {
					console.log(3)
					toast.error('One coupon already applied')
				}
				setShowAppliedCoupon(true)
			} else {
				toast.error(data?.message)
			}
		}
	}

	const handleCreateBooking = async () => {
		const sumRoomsNumber = selectedRoomsWithNumber.reduce(
			(accumulator, room) => accumulator + room.number,
			0,
		)
		const totalPaid = totalPrice + totalExtraPrices + totalBuyerPrices

		const roomIds = selectedRoomsWithNumber.map(selectedRoom => {
			return {
				hotel_room_id: selectedRoom.id,
				price: selectedRoom.price,
				no_of_rooms: selectedRoom.number,
			}
		})

		const extraPriceInformation = selectedExtraPrices.map(
			selectedExtraPrice => {
				return {
					id: selectedExtraPrice._id,
					price: selectedExtraPrice.price,
					title: selectedExtraPrice.name,
					type: selectedExtraPrice.type,
					is_per_person: selectedExtraPrice.is_per_person,
				}
			},
		)

		if (!validateBooking(from, to, adults, childrens, sumRoomsNumber)) {
			return
		}

		const loggedUserData = await getLoggedUserData()

		if (!loggedUserData) {
			const returnTo = window.location.pathname + window.location.search + window.location.hash + `&selectedRooms=${JSON.stringify(selectedRoomsWithNumber)}`
			
			const encodedReturnTo = encodeURIComponent(returnTo)
			navigate(`/login?returnTo=${encodedReturnTo}`, {
				state: {
					from: window.location.pathname,
				},
			})
		}

		setLoading(true)

		const startdate = moment(from).format("YYYY-MM-DD")
		const enddate = moment(to).format("YYYY-MM-DD")

		const booking = {
			hotel_id: property?._id,
			room_ids: roomIds,
			author_id: loggedUserData._id,
			checkin_date: startdate,
			checkout_date: enddate,
			no_of_adults: adults,
			no_of_chlidrens: childrens,
			no_of_rooms: sumRoomsNumber,
			payment_method: 'Online',
			total_paid: totalPaid,
			total_service_price: totalBuyerPrices,
			total_extra_price: totalExtraPrices,
			discount: appliedDiscount,
			promocode: appliedCoupon?.code,
			address: property?.real_address,
			extra_price_information: extraPriceInformation,
		}

		const { data, status } = await axios.post(`/Booking/create`, booking)

		if (status === 200 || status === 201) {
			navigate(`/booking-property/${data?._id}`)
		}

		setLoading(false)
	}

	return (
		<>
			<div className="app-component-booking-detail-card">
				<div className="app-component-booking-detail-heading">
					<h4>Booking Details</h4>
					<p>{`${property?.title}, ${property?.real_address}`}</p>
					{selectedRoomsWithNumber.map((selectedRoom, index) => (
						<p key={index}>{`${selectedRoom.number} ${selectedRoom.number > 1 ? 'Rooms' : 'Room'
							} - ${selectedRoom.name}`}</p>
					))}
					<p>
						{`${adults} ${adults > 1 ? 'Adults' : 'Adult'} - ${childrens} ${childrens > 1 ? 'Childrens' : 'Children'
							} - ${numberOfDays} ${numberOfDays > 1 ? 'Nights' : 'Night'}`}
					</p>
				</div>
				<div className="app-component-booking-detail-actions">
					<h4>Extra Services</h4>
					<FormGroup>
						{property?.HotelExtraPrices.map(extraPrice => (
							<FormControlLabel
								key={extraPrice._id}
								control={<Checkbox />}
								value={extraPrice._id}
								label={extraPrice.name}
								onChange={handleExtraPriceChange}
							/>
						))}
					</FormGroup>
				</div>
				<div className="booking-detail-apply-coupon">
					<Button onClick={handleOpenModal}>
						<div className="booking-detail-apply-coupon-content">
							<img src="/assets/images/discount.svg" />
							<span>Apply coupon</span>
						</div>
						<img
							className="apply-coupon-arrow"
							src="/assets/images/next-primary.svg"
						/>
					</Button>
				</div>
				{showAppliedCoupon && (
					<div className="booking-detail-applied-coupon">
						<div className="">
							<h5>
								Applied <span>{appliedCoupon.code}</span>
							</h5>
							<p>
								you saved <span>{`₹ ${appliedDiscount}`}</span> on coupon
							</p>
						</div>
						<IconButton
							onClick={() => {
								setAppliedCoupon(null)
								setShowAppliedCoupon(false)
							}}
						>
							<img src="/assets/images/close.svg" />
						</IconButton>
					</div>
				)}
				<div className="booking-price-details">
					<h3 className="booking-price-details-heading">Price Details</h3>
					<ul className="booking-price-details-list">
						{selectedRoomsWithNumber.map((selectedRoom, index) => (
							<li key={index} className="booking-price-details-list-item">
								<p>{`${selectedRoom.number} ${selectedRoom.number > 1 ? 'Rooms' : 'Room'
									} - ${selectedRoom.name} - ${numberOfDays} ${numberOfDays > 1 ? 'Nights' : 'Night'
									}`}</p>
								<span>{`₹ ${selectedRoom.price * selectedRoom.number}`}</span>
							</li>
						))}
						{selectedExtraPrices.map((selectedExtraPrice, index) => (
							<li key={index} className="booking-price-details-list-item">
								<p>{selectedExtraPrice.is_per_person ? `${adults + childrens} ${selectedExtraPrice?.name}` : selectedExtraPrice?.name}</p>
								<span>{`₹ ${(adults + childrens) * selectedExtraPrice.price}`}</span>
							</li>
						))}
						{property?.HotelBuyerPrices.map(buyerPrice => (
							<li
								key={buyerPrice._id}
								className="booking-price-details-list-item"
							>
								<p>
									{buyerPrice.name}
									<img
										aria-owns={open ? 'mouse-over-popover' : undefined}
										aria-haspopup="true"
										onMouseEnter={handlePopoverOpen}
										onMouseLeave={handlePopoverClose}
										src="/assets/images/info.svg"
									/>
									<Popover
										id="mouse-over-popover"
										sx={{
											pointerEvents: 'none',
										}}
										open={open}
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'center',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
										onClose={handlePopoverClose}
										disableRestoreFocus
									>
										<h6 className="popover-text">{buyerPrice.content}</h6>
									</Popover>
								</p>
								<span>{`₹ ${buyerPrice.is_price_per_person
										? buyerPrice.price * (adults + childrens)
										: buyerPrice.price
									}`}</span>
							</li>
						))}

						<li className="booking-price-details-list-item booking-price-details-list-item-total">
							<p>Total</p>
							<span>{`₹ ${totalPrice + totalExtraPrices + totalBuyerPrices
								}`}</span>
						</li>
					</ul>
				</div>
				<div className="booking-price-details-conditions">
					<ul>
						{property?.HotelPolicy.map(policy => (
							<Tooltip key={policy._id} title={policy.content}>
								<li>{policy.name}</li>
							</Tooltip>
						))}
					</ul>
				</div>
				<div style={{ paddingBottom: "40px" }} className="booking-price-details-button">
					<Button
						className="app-custom-button"
						style={{ backgroundColor: loading ? "grey" : undefined }}
						color={loading ? undefined : "primary"}
						variant="contained"
						onClick={loading ? undefined : () => handleCreateBooking()}
					>
						Continue your booking
					</Button>
				</div>
			</div>
			<div>
				<Modal
					open={openmodal}
					onClose={handleCloseModal}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<div className="app-coupon-modal">
						<div className="app-coupon-modal-heading">
							<h2>Enter your details</h2>
							<IconButton onClick={handleCloseModal}>
								<img src="/assets/images/close-rounded.svg" />
							</IconButton>
						</div>
						<div className="app-coupon-modal-search">
							<span>Have a promo code?</span>
							<div className="app-coupon-modal-search-form">
								<form onSubmit={onSubmitCoupon}>
									<input
										type={'text'}
										value={promoCode}
										onChange={evt => {
											setPromoCode(evt.target.value)
										}}
										placeholder="Enter Your Promo Code"
									/>
									<Button color="primary" type="submit">
										Apply
									</Button>
								</form>
							</div>
						</div>
						<div className="app-coupons-content">
							<ul className="app-coupons-list">
								{coupons.map(coupon => (
									<li key={coupon._id} className="app-coupons-list-item">
										<CouponCard
											coupon={coupon}
											totalPrice={totalPrice}
											totalPriceWithoutDiscount={totalPriceWithoutDiscount}
											adults={adults}
											childrens={childrens}
											coupons={coupons}
											appliedCoupon={appliedCoupon}
											setAppliedCoupon={setAppliedCoupon}
											setShowAppliedCoupon={setShowAppliedCoupon}
											handleCloseModal={handleCloseModal}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}
export default BookingDetails
