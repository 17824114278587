import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import React from 'react';
import { Link } from 'react-router-dom';

const MainCta = () => {
  return (
    <>
      <div className="app-component-main-cta">
        <Container maxWidth="lg">
          <div className="app-component-main-cta-wrapper">
            <h1>Limited Rooms Available! Book Your Dream Stay Today</h1>
            <p>Don't miss out - secure your perfect hotel now!</p>
            <div className="app-component-main-cta-btn">
              <Link to="/properties-listing?locationId=&locationTitle=&from=&to=&adults=1&childrens=0&rooms=1">
                <Button
                  variant="contained"
                  color="primary"
                  className="app-custom-button"
                >
                  Book Now
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default MainCta;
