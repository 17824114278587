import { CircularProgress, IconButton, useStepContext } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Container } from '@mui/system'
import BookingDetails from '../components/BookingDetails'
import TopHeader from '../components/layouts/TopHeader'
import PropertyGallery from '../components/PropertyGallary'
import dayjs from 'dayjs'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom'
import { WhatsappShareButton } from 'react-share'
import axios, { apiBaseUrl } from '../services/axios'
import { getSavedPricePercentage } from '../utilities/Costs'
import Footer from '../components/layouts/Footer'
import RoomCard from '../components/RoomCard'
import RoomSearch from '../components/RoomSearch'
import { QRCodeCanvas } from 'qrcode.react'
import { BiSolidDownload } from "react-icons/bi"
import "./css/PropertyInner.css"
import { FaCheck } from "react-icons/fa";

const prepareApiSearchParams = (
	searchParamFrom,
	searchParamTo,
	searchParamAdults,
	searchParamChildrens,
	searchParamRooms,
) => {
	const apiSearchParams = new URLSearchParams()
	searchParamFrom !== "null" && apiSearchParams.append('checkin_at', searchParamFrom)
	searchParamTo !== "null" && apiSearchParams.append('checkout_at', searchParamTo)
	searchParamAdults !== "null" && apiSearchParams.append('no_of_guests', searchParamAdults)
	searchParamChildrens !== "null" && apiSearchParams.append('no_of_childrens', searchParamChildrens)
	searchParamRooms !== "null" && apiSearchParams.append('no_of_rooms', searchParamRooms)
	return apiSearchParams
}

const PropertyInner = () => {
	const { id } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()

	const searchParamFrom = searchParams.get('from')
	const searchParamTo = searchParams.get('to')
	const searchParamAdults = searchParams.get('adults')
	const searchParamChildrens = searchParams.get('childrens')
	const searchParamRooms = searchParams.get('rooms')

	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const [property, setProperty] = useState(null)
	const [totalPrice, setTotalPrice] = useState(0)
	const [numberOfDays, setNumberOfDays] = useState(0)
	const [selectedRooms, setSelectedRooms] = useState([])
	const [isFavourite, setIsFavourite] = useState(false)
	const [totalPriceWithoutDiscount, setTotalPriceWithoutDiscount] = useState(0)

	const calculateNumberOfDays = () => {
		if (searchParamFrom && searchParamTo) {
			const from = dayjs(searchParamFrom)
			const to = dayjs(searchParamTo)
			const diffInDays = to.diff(from, 'day')
			setNumberOfDays(diffInDays)
		}
	}

	const calculateTotalPrice = () => {
		let calculatedTotalPrice = 0
		selectedRooms.forEach(selectedRoom => {
			calculatedTotalPrice =
				calculatedTotalPrice + selectedRoom.number * selectedRoom.price
		})
		setTotalPrice(calculatedTotalPrice)
		setTotalPriceWithoutDiscount(calculatedTotalPrice)
	}

	useEffect(() => {
		calculateNumberOfDays()
	}, [searchParamFrom, searchParamTo])

	useEffect(() => {
		let newSelectedRooms = []
		if (property && property.HotelRooms && numberOfDays > 0) {
			newSelectedRooms = property.HotelRooms.map(room => {
				return {
					id: room?._id,
					number: 0,
					name: room?.name,
					// price: numberOfDays * room?.price,
					price: room?.price,
				}
			})
		}
		setSelectedRooms(newSelectedRooms)
	}, [property, numberOfDays])

	useEffect(() => {
		calculateTotalPrice()
	}, [selectedRooms])

	const getProperty = async () => {
		setLoading(true)
		const apiSearchParams = prepareApiSearchParams(
			searchParamFrom,
			searchParamTo,
			searchParamAdults,
			searchParamChildrens,
			searchParamRooms,
		)

		const { data, status } = await axios.get(`Hotel/view/${id}?${apiSearchParams}`)

		if (status === 200 || status === 201) {
			setProperty(data)
			setIsFavourite(data.isFavourited > 0 ? true : false)
			setLoading(false)
		}
	}

	const getMinimumProperty = async () => {
		setSearchParams(searchParams => {
			searchParams.set('adults', "1")
			searchParams.set('childrens', "0")
			searchParams.set('rooms', "1")
			return searchParams
		})
	}

	useEffect(() => {
		getProperty()
	}, [
		searchParamFrom,
		searchParamTo,
		searchParamAdults,
		searchParamChildrens,
		searchParamRooms,
	])

	const handleReturnToListing = () => {
		navigate(`/properties-listing?${searchParams}`)
	}

	const handleAddFavourite = async () => {
		setIsFavourite(!isFavourite)
		await axios.post(`/Favourite/create`, {
			hotel_id: property?._id,
		})
	}

	const handleQRDownload = () => {
		const canvas = document.getElementById("_9evy-qrcode")
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream")
		let downloadLink = document.createElement("a")
		downloadLink.href = pngUrl
		downloadLink.download = property ? `${property.title} - booking.png` : "QR.png"
		document.body.appendChild(downloadLink)
		downloadLink.click()
		document.body.removeChild(downloadLink)
	}

	const galleryimages = property ? property.HotelGallery : []
	const youtubelink = property ? property.youtube_video_link : undefined

	console.log({ property })

	return (
		<>
			<div className="_0ubx app-page-property-inner">
				<div className="app-page-property-inner-header">
					<TopHeader />
				</div>
				{loading && (
					<div className="app-loading">
						<CircularProgress color="inherit" />
					</div>
				)}
				{!loading && (
					<>
						<div className="app-page-property-inner-breadcrumb">
							<Container maxWidth="lg">
								<div className="app-page-property-inner-breadcrumb-wrapper">
									<div className="app-page-property-inner-breadcrumb-links">
										<ul>
											<li>
												<Link to={'/home'}>Home</Link>
											</li>
											<li>
												<button onClick={handleReturnToListing}>
													{property?.real_address}
												</button>
											</li>
											<li>
												<span>{property?.title}</span>
											</li>
										</ul>
										<p>
											Lorem Ipsum is simply dummy text of the printing Lorem
											Ipsum
										</p>
									</div>
									<div className="app-page-property-inner-breadcrumb-extra">
										<ul>
											<li>
												<IconButton onClick={handleAddFavourite}>
													{!isFavourite && (
														<img src="/assets/images/heart-outlined.svg" />
													)}
													{isFavourite && (
														<img src="/assets/images/heart-filled.svg" />
													)}
												</IconButton>
											</li>
											<li>
												<WhatsappShareButton url={window.location.href}>
													<div className="app-share-button">
														<img src="/assets/images/share.svg" />
														<span>Share</span>
													</div>
												</WhatsappShareButton>
											</li>
										</ul>
									</div>
								</div>
							</Container>
						</div>
						<div className="app-page-property-inner-gallery">
							<Container maxWidth="lg">
								<div style={{ position: "relative" }} className="">
									<PropertyGallery property={property} />
									<div style={{ position: "absolute", right: "0px", top: "0px", width: "400px" }} className="">
										<div style={{ position: "relative", height: "2100px" }}>
											<div style={{ position: "sticky", top: "20px" }}>
												<BookingDetails
													property={property}
													totalPrice={totalPrice}
													setTotalPrice={setTotalPrice}
													totalPriceWithoutDiscount={totalPriceWithoutDiscount}
													numberOfDays={numberOfDays}
													selectedRooms={selectedRooms}
												/>
											</div>
										</div>
									</div>
								</div>
								<div style={{ marginTop: "20px" }}>
									<span>Images gallery</span>
									<div style={{ display: "flex", gap: "15px", marginTop: "20px" }}>
										{galleryimages.map(item => {
											return (
												<div key={item._id} style={{ width: "150px", height: "150px", borderRadius: "10px", overflow: "hidden", border: "1px solid #F4E0B9" }}>
													<img style={{ width: "150px", height: "150px", objectFit: "cover" }} src={item.image ? (item.image.includes("amazonaws.com") ? item.image : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png") : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png"} />
												</div>
											)
										})}
									</div>
								</div>
							</Container>
						</div>
						<div className="app-page-property-inner-detail">
							<Container maxWidth="lg">
								<Grid container spacing={4}>
									<Grid item xs={7}>
										<div className="app-page-property-inner-detail-content">
											<div className="app-page-property-inner-card-details">
												<div className="property-card-details-reviews">
													<div className="property-card-details-reviews-card">
														<img src="/assets/images/star.svg" />
														<span>{property?.rating_standard}</span>
													</div>
													<p>{`${property?.Reviews?.length} Reviews`}</p>
												</div>
												<div className="property-card-details-heading">
													<div className="property-card-details-info">
														<h4>{property?.title}</h4>
														<p>{property?.real_address}</p>
													</div>
												</div>
												<div className="property-card-details-price">
													<h2>{`₹ ${property?.selling_price}`}</h2>
													{property?.price &&
														property?.price !== property?.selling_price && (
															<>
																<h3>{`₹ ${property?.price}`}</h3>
																<span>{`${Math.round(getSavedPricePercentage(
																	property?.selling_price,
																	property?.price,
																))}% Off`}</span>
															</>
														)}
												</div>
											</div>
											<div className="app-page-property-inner-detail-description">
												<h4 className="app-page-property-inner-detail-heading">
													Description
												</h4>
												<p>{property?.content}</p>
											</div>
											<div className="app-page-property-inner-detail-amenities">
												{property?.amenities.length ? <h4 className="app-page-property-inner-detail-heading">
													Amenities
												</h4> : undefined}
												<ul className="property-inner-detail-amenities-list">
													{property?.amenities.map((amenity, index) => (
														<li style={{ display: "flex", alignItems: "center", gap: "5px" }} key={index}>
															<FaCheck style={{ color: "#ff6431" }} />
															<span>{amenity.title}</span>
														</li>
													))}
												</ul>
											</div>
											<div>
												<h4 className="app-page-property-inner-detail-heading" style={{ marginBottom: "15px" }}>
													Video
												</h4>
												{youtubelink ? (
													<div>
														<iframe width="560" height="315" src={youtubelink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
													</div>
												) : undefined}
											</div>
											<div className="app-hotel-rooms">
												<div className="app-hotel-rooms-search">
													<RoomSearch />
												</div>
												<ul className="app-hotel-rooms-list">
													{property?.HotelRooms.length ? property?.HotelRooms.map((room, index) => {
														const availability = { online: room.no_of_online_rooms - room.online_booked, offline: room.no_of_online_rooms }

														return (
															<li
																key={index}
																className="app-hotel-rooms-list-item"
															>
																<RoomCard
																	room={room}
																	numberOfDays={numberOfDays}
																	setSelectedRooms={setSelectedRooms}
																	maxBookableRooms={availability}
																/>
															</li>)
													}) : <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", backgroundColor: "white", marginTop: "20px", padding: "20px", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", borderLeft: "3px solid red" }}>
														<span>No room available</span>
														<button onClick={() => getMinimumProperty()} style={{ cursor: "pointer", backgroundColor: "white", border: "none", color: "green" }}>See other options</button>
													</div>
													}
												</ul>
											</div>
											<div className="app-page-property-inner-detail-map">
												<h4 className="app-page-property-inner-detail-heading">
													Map
												</h4>
												<div className="app-page-property-inner-detail-map-iframe">
													<iframe
														width="100%"
														height="600"
														frameBorder="0"
														scrolling="no"
														marginHeight={0}
														marginWidth={0}
														src={`https://maps.google.com/maps?hl=en&q=${property?.map_latitude},${property?.map_longitude}&t=&amp;z=14&ie=UTF8&iwloc=B&output=embed`}
													></iframe>
												</div>
											</div>
											<div style={{ marginTop: "20px" }}>
												<div style={{ display: "flex", alignItems: "center", gap: "10px", fontSize: "18px", fontWeight: "600" }}>QR Code <span style={{ fontSize: "14px", fontWeight: "400", color: "grey" }}>(Scan and book with ease on other device)</span></div>
												<div className="fleecer-pis" onClick={() => handleQRDownload()} style={{ position: "relative", width: "fit-content" }}>
													<QRCodeCanvas
														id="_9evy-qrcode"
														style={{ marginTop: "20px" }}
														value={window.location}
														fgColor="rgb(75, 75, 75)"
													/>
													<div className="staples-cool" style={{ position: "absolute", bottom: "0px", right: "0px", justifyContent: "center", alignItems: "center", backgroundColor: "#ff6431", width: "30px", height: "30px" }}>
														<BiSolidDownload
															style={{ fill: "white" }}
														/>
													</div>
												</div>
											</div>
											<div className="app-page-property-inner-detail-hotelpolicies">
												<h4 className="app-page-property-inner-detail-heading">
													Hotel Policies
												</h4>
												<div className="app-page-property-inner-detail-checkin">
													<ul>
														<li>
															<p>Check In</p>
															<h2>{property?.timeforcheckin}</h2>
														</li>
														<li>
															<p>Check Out</p>
															<h2>{property?.timeforcheckout}</h2>
														</li>
													</ul>
												</div>
												<div className="app-page-property-inner-detail-policies">
													<ul>
														{property?.HotelPolicy.map(hotelPolicy => (
															<li key={hotelPolicy._id}>
																{hotelPolicy.content}
															</li>
														))}
													</ul>
													<div className="app-page-property-inner-detail-policies-link">
														<Link to={''}>View Guest Policy</Link>
													</div>
												</div>
											</div>
										</div>
									</Grid>
									<Grid item xs={5}>
										{/* <div className="">
											<BookingDetails
												property={property}
												totalPrice={totalPrice}
												setTotalPrice={setTotalPrice}
												numberOfDays={numberOfDays}
												selectedRooms={selectedRooms}
											/>
										</div> */}
									</Grid>
								</Grid>
							</Container>
						</div>
						<div className="">
							<Footer />
						</div>
					</>
				)}
			</div>
		</>
	)
}
export default PropertyInner
