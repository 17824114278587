import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from '../../services/axios'
import { registerSchema } from '../../services/validations'
import TopHeader from '../../components/layouts/TopHeader'
import VerifyOtp from "../../components/VerifyOtp"
import { firebase, auth } from "../../config/firebase"

const Register = () => {
	const [verifyOtpPopup, setVerifyOtpPopup] = useState(false)
	const [submittedForm, setSubmittedForm] = useState(undefined)
	const [phonecode, setPhonecode] = useState("+91")
	const [otpVerifier, setOtpVerifier] = useState(undefined)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(registerSchema),
	})

	const navigate = useNavigate()

	function setuprecaptcha() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible',
            callback: function (response) {
                console.log("recature resolved")
                this.onSignInSubmit()
            }
        })
    }

	const sendAuthToBackend = async (result) => {
        const { data, status } = await axios.post('Author/create', submittedForm)

		if (status === 200 || status === 201) {
			toast.success('User registered success')
			navigate('/home')
		}
		else {
			toast.error("Something went wrong")
			console.log({ data })
		}
    }

	// Sent OTP
    const signin = (formattedPhone) => {
        if (formattedPhone === "" || formattedPhone.length < 10) return

        var verify = window.recaptchaVerifier

        auth.signInWithPhoneNumber(formattedPhone, verify).then((result) => {
			setOtpVerifier(result)
			setVerifyOtpPopup(true)
        })
		.catch((err) => {
			alert(err)
			window.location.reload()
		})
    }

	// Validate OTP
    const ValidateOtp = (otp) => {
        if (otp === null || otpVerifier === null || otpVerifier === undefined)
            return
        otpVerifier.confirm(otp).then((result) => {
            // success
            sendAuthToBackend(result)
        }).catch((err) => {
            alert("Wrong code")
        })
    }

	const onSubmit = async formData => {
		setSubmittedForm(formData)
		signin(`${phonecode}${formData.phonenumber}`)
	}

	useEffect(() => {
		setuprecaptcha()
	}, [])

	return (
		<>
			<div className="login-bg">
				<div id="recaptcha-container"></div>
				{verifyOtpPopup ? <VerifyOtp setPopup={setVerifyOtpPopup} formData={submittedForm} ValidateOtp={ValidateOtp} /> : undefined}
				<div className="">
					<TopHeader />
				</div>
				<Container>
					<div className="login-card-wrapper">
						<div className="login-card">
							<div className="login-card-content">
								<h2>Sign Up</h2>
								<p>Lorem Ipsum is simply dummy text of the printing</p>
							</div>
							<div className="login-card-form">
								<form onSubmit={handleSubmit(onSubmit)}>
									<ul>
										<li>
											<div className="field-wrapper">
												<div className="app-form-field">
													<input
														type={'text'}
														placeholder="Enter your Name"
														{...register('first_name')}
													/>
													<img
														className="input-append-left email-icon"
														src="/assets/images/user.svg"
													/>
												</div>
												{errors.first_name && (
													<div className="error-message">
														{errors.first_name.message}
													</div>
												)}
											</div>
										</li>
										<li>
											<div className="field-wrapper">
												<div className="app-form-field">
													<input
														type={'email'}
														placeholder="Enter your Email"
														{...register('email')}
													/>
													<img
														className="input-append-left email-icon"
														src="/assets/images/email.svg"
													/>
												</div>
												{errors.email && (
													<div className="error-message">
														{errors.email.message}
													</div>
												)}
											</div>
										</li>
										<li>
											<div className="field-wrapper">
												<div className="app-form-field">
													<input
														type={'number'}
														placeholder="Enter your Conatct Number"
														{...register('phonenumber')}
													/>
													<img
														className="input-append-left email-icon"
														src="/assets/images/telephone.svg"
													/>
												</div>
												{errors.phonenumber && (
													<div className="error-message">
														{errors.phonenumber.message}
													</div>
												)}
											</div>
										</li>
										<li>
											<div className="field-wrapper">
												<div className="app-form-field">
													<input
														type={'password'}
														placeholder="Enter your New Password"
														{...register('password')}
													/>
													<img
														className="input-append-left"
														src="/assets/images/lock.svg"
													/>
												</div>
												{errors.password && (
													<div className="error-message">
														{errors.password.message}
													</div>
												)}
											</div>
										</li>
										<li>
											<div className="field-wrapper">
												<div className="app-form-field">
													<input
														type={'password'}
														placeholder="Confirm your Password"
														{...register('confirmPassword')}
													/>
													<img
														className="input-append-left"
														src="/assets/images/lock.svg"
													/>
												</div>
												{errors.confirmPassword && (
													<div className="error-message">
														{errors.confirmPassword.message}
													</div>
												)}
											</div>
										</li>
										<li>
											<div className="app-form-button">
												<Button
													type="submit"
													color="primary"
													variant="contained"
												>
													Register
												</Button>
											</div>
										</li>
									</ul>
								</form>
								<div className="login-card-form-bottom">
									<Link to="/login">
										<span className="app-forgot-pass-text">Login</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}
export default Register
