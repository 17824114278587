import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';
import TopHeader from '../components/layouts/TopHeader';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios, { apiBaseUrl } from '../services/axios';
import { getSavedPricePercentage } from '../utilities/Costs';

const Wishlist = () => {
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFavourites = async () => {
    setLoading(true);
    const { data, status } = await axios.get(`/Favourite/list`);
    if (status === 200 || status === 201) {
      setFavourites(data?.results);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavourites();
  }, []);

  const handleRemove = async id => {
    const { status } = await axios.delete(`/Favourite/delete/${id}`);
    if (status === 204) {
      toast.success('Favourite removed successfully');
      getFavourites();
    }
  };

  return (
    <>
      <div className="app-page-bookings app-page-wishlist">
        <div className="app-page-bookings-header">
          <TopHeader />
        </div>
        <div className="app-page-bookings-subheader">
          <Container maxWidth="lg">
            <div className="app-page-bookings-subheader-wrapper">
              <h1 className="bookings-subheader-heading">Wishlist</h1>
            </div>
          </Container>
        </div>
        {loading && (
          <div className="app-loading">
            <CircularProgress color="inherit" />
          </div>
        )}
        {!loading && (
          <div className="app-page-bookings-content">
            <Container maxWidth="lg">
              <div className="app-page-bookings-tab-content">
                <ul className="app-page-bookings-tab-content-list">
                  {favourites.map(favourite => (
                    <li
                      key={favourite._id}
                      className="app-page-bookings-tab-content-list-item"
                    >
                      <div className="app-bookings-card">
                        <div className="app-bookings-card-content">
                          <img
                            className="app-bookings-card-content-image"
                            src="https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/jeffrey-francisco-_Ei9f33bQ1A-unsplash.jpg"
                          />
                          <div className="app-bookings-card-info">
                            <div className="app-comoponent-property-card-details">
                              <div className="property-card-details-heading">
                                <div className="property-card-details-info">
                                  <h4>{favourite.hotel_id.title}</h4>
                                  <p>{favourite.hotel_id.real_address}</p>
                                </div>
                              </div>
                              <div className="property-card-details-reviews">
                                <div className="property-card-details-reviews-card">
                                  <img src="/assets/images/star.svg" />
                                  <span>
                                    {favourite.hotel_id.rating_standard}
                                  </span>
                                </div>
                                <p>
                                  {favourite.hotel_id.ReviewsCount
                                    ? `${favourite.hotel_id.ReviewsCount} Reviews`
                                    : ''}
                                </p>
                              </div>
                              <div className="property-card-details-price">
                                <h2>{`₹ ${favourite.hotel_id.price}`}</h2>
                                {favourite.hotel_id.selling_price &&
                                  favourite.hotel_id.price !==
                                    favourite.hotel_id.selling_price && (
                                    <>
                                      <h3>{`₹ ${favourite.hotel_id.selling_price}`}</h3>
                                      <span>{`${getSavedPricePercentage(
                                        favourite.hotel_id.price,
                                        favourite.hotel_id.selling_price,
                                      )}% Off`}</span>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="app-bookings-card-buttons">
                          <ul>
                            <li>
                              <Link
                                to={`/properties-inner/${favourite.hotel_id._id}?locationId=&locationTitle=&from=&to=&adults=1&childrens=0&rooms=1`}
                              >
                                <Button
                                  className="app-primary-btn-with-icon"
                                  variant="outlined"
                                >
                                  <img src="/assets/images/view.svg" />
                                  <span>View</span>
                                </Button>
                              </Link>
                            </li>
                            <li>
                              <Button
                                className="app-grey-btn-with-icon"
                                variant="outlined"
                                onClick={() => {
                                  handleRemove(favourite._id);
                                }}
                              >
                                <img src="/assets/images/close.svg" />
                                <span>Remove</span>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
};
export default Wishlist;
