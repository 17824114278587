import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from '../services/axios';
import { forgotPasswordSchema } from '../services/validations';
import TopHeader from '../components/layouts/TopHeader';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const navigate = useNavigate();
  const onSubmit = async formData => {
    const { data, status } = await axios.post(
      `/Author/forgot-password/`,{email: formData.email}
    );
    if (data.success) {
      navigate(`/email-sent`, { state: { email: formData.email } });
    }
  };

  return (
    <>
      <div className="login-bg">
        <div className="">
          <TopHeader />
        </div>
        <Container>
          <div className="login-card-wrapper">
            <div className="login-card">
              <div className="login-card-content">
                <h2>Forgot Password</h2>
                <p>Enter your email to send the link</p>
              </div>
              <div className="login-card-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    <li>
                      <div className="field-wrapper">
                        <div className="app-form-field">
                          <input
                            type={'email'}
                            placeholder="Enter your Email"
                            {...register('email')}
                          />
                          <img
                            className="input-append-left email-icon"
                            src="/assets/images/email.svg"
                          />
                        </div>
                        {errors.email && (
                          <div className="error-message">
                            {errors.email.message}
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="app-form-button">
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ForgotPassword;
