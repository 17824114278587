import { Button, IconButton, Tooltip } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { apiBaseUrl } from '../services/axios'
import '../styles/components/room-card.scss'
import { BsInfoCircleFill } from "react-icons/bs"

const RoomCard = ({ room, numberOfDays, setSelectedRooms, maxBookableRooms }) => {
	const [searchParams, setSearchParams] = useSearchParams()

	const searchParamFrom = searchParams.get('from')
	const searchParamTo = searchParams.get('to')
	const searchParamAdults = searchParams.get('adults')
	const searchParamChildrens = searchParams.get('childrens')
	const searchParamRooms = searchParams.get('rooms')
	const searchParamsSelectedRooms = searchParams.get('selectedRooms')

	const [roomsNumber, setRoomsNumber] = useState(0)
	const priceForNights = room?.price

	useEffect(() => {
		setSelectedRooms(currentSelectedRooms => {
			return currentSelectedRooms.map(selectedRoom => {
				if (selectedRoom.id === room?._id) {
					return {
						...selectedRoom,
						number: roomsNumber,
					}
				} else {
					return selectedRoom
				}
			})
		})
	}, [roomsNumber])

	useEffect(() => {
		if (searchParamsSelectedRooms) {
			const selectedRooms = JSON.parse(searchParamsSelectedRooms)

			const __room = selectedRooms.find(item => item.id === room._id)

			if (__room) {
				setRoomsNumber(__room.number)
			}
		}
	}, [searchParamsSelectedRooms])

	return (
		<>
			<div className="app-component-room-card">
				<div style={{ display: "flex", justifyContent: "flex-end", fontSize: "14px" }} >
					{maxBookableRooms.online <= 0 ? <span style={{ backgroundColor: "rgb(207, 75, 75)", color: "white", padding: "3px 10px", borderRadius: "5px" }}>Room already booked</span> : <span style={{ backgroundColor: "rgb(83, 190, 83)", color: "white", padding: "3px 10px", borderRadius: "5px" }}>Available: {maxBookableRooms.online} Rooms</span>}
				</div>
				<div className="app-component-room-card-heading">
					<h4>{room?.name}</h4>
				</div>
				<div className="app-component-room-card-wrapper">
					<div className="app-component-room-card-image">
						<img src={room.icon ? (room.icon.includes("amazonaws.com") ? room.icon : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png") : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png"} />
					</div>
					<div className="app-component-room-card-content">
						<div className="app-component-room-card-benfits">
							<ul className="app-component-room-card-details">
								<Tooltip title="Room size">
									<li className="app-component-room-card-details-item">
										<img src="/assets/images/rulers.svg" />
										<span>{`${room?.room_size_in_sqft} sqft`}</span>
									</li>
								</Tooltip>
								<Tooltip title="No. of Beds">
									<li className="app-component-room-card-details-item">
										<img src="/assets/images/bed.svg" />
										<span>{`x${room?.number_of_beds}`}</span>
									</li>
								</Tooltip>
								<Tooltip title="No. of Adults">
									<li className="app-component-room-card-details-item">
										<img src="/assets/images/couple.svg" />
										<span>{`x${room?.max_adults}`}</span>
									</li>
								</Tooltip>
								<Tooltip title="No. of Childrens">
									<li className="app-component-room-card-details-item">
										<img src="/assets/images/baby-boy.svg" />
										<span>{`x${room?.max_children}`}</span>
									</li>
								</Tooltip>
							</ul>
							<ul className="app-component-room-card-amenities">
								{room?.amenities.map((amenity, index) => (
									<li
										key={index}
										className="app-component-room-card-amenities-item"
									>
										<img src="https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/christian-lambert-ycyXpdhCnp8-unsplash.jpg" />
										<span>{amenity.title}</span>
									</li>
								))}
							</ul>
						</div>
						<div className="app-component-room-card-price">
							<h5>{`₹${priceForNights}/${numberOfDays} nights`}</h5>
							<div className="app-component-room-card-price-add">
								<div className="guest-select-option-increment">
									<div className="guest-select-option-button guest-select-option-button-left">
										<Button
											disabled={numberOfDays ? false : true}
											type="button"
											variant="outlined"
											color="primary"
											onClick={() => {
												setRoomsNumber(oldValue => {
													return oldValue > 0 ? oldValue - 1 : 0
												})
											}}
										>
											-
										</Button>
									</div>
									<div className="guest-select-option-input">
										<input type={'text'} value={roomsNumber} readOnly />
									</div>
									<div className="guest-select-option-button guest-select-option-button-right">
										<Button
											disabled={numberOfDays ? false : true}
											style={maxBookableRooms.online <= roomsNumber ? { color: "white", backgroundColor: "#c9c9c9", cursor: "default", borderColor: "#c9c9c9" } : undefined}
											type="button"
											variant="outlined"
											color="primary"
											onClick={() => {
												if (maxBookableRooms.online > roomsNumber) {
													setRoomsNumber(oldValue => {
														return oldValue < room?.no_of_rooms
															? oldValue + 1
															: room?.no_of_rooms
													})
												}
											}}
										>
											+
										</Button>

									</div>
									{numberOfDays ? undefined : <Tooltip title="Please select a date and then check availability" arrow>
										<div>
											<BsInfoCircleFill style={{ color: "rgb(83, 190, 83, 0.6)", width: "18px", height: "18px", marginLeft: "8px", transform: "translateY(2px)", cursor: "help" }} />
										</div>
									</Tooltip>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default RoomCard
