import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React from 'react';

import Search from './Search';

const Banner = () => {
  return (
    <>
      <div className="app-component-banner">
        <Container maxWidth="lg">
          <div className="app-component-banner-wrapper">
            <div className="app-component-banner-content">
              <h1>
                Find <span>Your Home</span> away from Home
              </h1>
              <p>
                Discover incredible hotels & deals. Book your perfect escape
                today!
              </p>
              <div className="app-component-banner-search">
                <Grid justifyContent="center" container>
                  <Grid item lg={12}>
                    <Search />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Banner;
