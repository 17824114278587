import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiBaseUrl } from '../services/axios';
import 'swiper/css';
import 'swiper/css/pagination';
import { getSavedPricePercentage } from '../utilities/Costs';

const PropertyCard = ({
  property,
  filtering,
  locationId,
  locationTitle,
  handleAddFavourite,
}) => {
  const [searchParams, setSearchParams] = useState(null);
  const [isFavourite, setIsFavourite] = useState(
    property?.isFavourited > 0 ? true : false,
  );

  console.log(1)

  const createSearchParams = () => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.append('locationId', locationId);
    newSearchParams.append('locationTitle', locationTitle);
    newSearchParams.append('from', filtering.from);
    newSearchParams.append('to', filtering.to);
    newSearchParams.append('adults', filtering.adults);
    newSearchParams.append('childrens', filtering.childrens);
    newSearchParams.append('rooms', filtering.rooms);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    createSearchParams();
  }, [filtering, property]);

  const handleClickAddFavourite = async evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsFavourite(!isFavourite);
    handleAddFavourite(property?._id);
  };

  console.log({ property })

  return (
    <>
      <Link to={`/properties-inner/${property._id}?${searchParams}`}>
        <div className="app-comoponent-property-card">
          <div className="app-comoponent-property-card-image">
            <div className="app-comoponent-property-card-image-content">
              <div className="app-comoponent-property-card-image-slider">
                <img src={property.banner_image ? (property.banner_image.includes("amazonaws.com") ? property.banner_image : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png") : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png"} />
              </div>
            </div>
            <div className="property-card-wishlist-icon">
              <IconButton onClick={handleClickAddFavourite}>
                {!isFavourite && <img src="/assets/images/heart.svg" />}
                {isFavourite && <img src="/assets/images/heart-filled.svg" />}
              </IconButton>
            </div>
          </div>
          <div className="app-comoponent-property-card-details">
            <div className="property-card-details-heading">
              <div className="property-card-details-info">
                <h4>{property?.title}</h4>
                <p>{property?.real_address}</p>
              </div>
              {property.distanceFromUser && <div className="property-card-details-distance">
                <img src="/assets/images/pin.svg" />
                <span>{Math.round(property.distanceFromUser / 1000)} Kms</span>
              </div>}
            </div>
            <div className="property-card-details-reviews">
              <div className="property-card-details-reviews-card">
                <img src="/assets/images/star.svg" />
                <span>{property?.rating_standard}</span>
              </div>
              <p>{`${property?.ReviewsCount} Reviews`}</p>
            </div>
            <div className="property-card-details-price">
              <h2>{`₹ ${property?.selling_price}`}</h2>
              {property?.price &&
                property?.price !== property?.selling_price && (
                  <>
                    <h3>{`₹ ${property?.price}`}</h3>
                    <span>{`${Math.round(getSavedPricePercentage(
                      property?.selling_price,
                      property?.price,
                    ))}% Off`}</span>
                  </>
                )}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
export default PropertyCard;
