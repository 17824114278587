import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Link } from 'react-router-dom';
import Facebook from '../../icons/social-media/Facebook';
import Instagram from '../../icons/social-media/Instagram';
import LinkedIn from '../../icons/social-media/LinkedIn';
import Twitter from '../../icons/social-media/Twitter';
function Footer() {
  return (
    <>
      <div className="app-component-footer">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <div className="app-component-footer-info">
                <img
                  className="app-footer-logo"
                  src="/assets/images/logo.svg"
                  alt=""
                />
                <p>Download 24 hours checkIn app for exciting offers.</p>
                <ul>
                  <li>
                    <Link to={''}>
                      <Button>
                        <img src="/assets/images/google-play.png" />
                      </Button>
                    </Link>
                  </li>
                  <li>
                    <Link to={''}>
                      <Button>
                        <img src="/assets/images/download-on-the-app-store.svg" />
                      </Button>
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} md={3} lg={2.5}>
              <div className="app-component-footer-links">
                <h3>Support</h3>
                <ul>
                  <li>
                    <Link to="">Help Center</Link>
                  </li>
                  <li>
                    <Link to="/CancellationOptions">Cancellation options</Link>
                  </li>
                  <li>
                    <Link to="">Our COVID-19 Response</Link>
                  </li>
                  <li>
                    <Link to="">Report a neighbourhood concern</Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} md={3} lg={2.5}>
              <div className="app-component-footer-links">
                <h3>About</h3>
                <ul>
                  <li>
                    <Link to="/about-page">About Us</Link>
                  </li>
                  <li>
                    <Link to="">Teams / Careers</Link>
                  </li>
                  <li>
                    <Link to="">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/ContactPage">Support</Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} md={3} lg={2.5}>
              <div className="app-component-footer-links">
                <h3>Policies</h3>
                <ul>
                  <li>
                    <Link to="/TermsandConditions">Terms and conditions</Link>
                  </li>
                  <li>
                    <Link to="/GuestPolicy">Guest Policies</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="">Trust And Safety</Link>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="app-component-footer-bottom">
          <Container maxWidth="lg">
            <div className="app-component-footer-bottom-content">
              <p>2023 © 24hours CheckIn</p>
              <ul>
                <li>
                  <Link to="">
                    <Facebook />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <LinkedIn />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <Instagram />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <Twitter />
                  </Link>
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
export default Footer;
