import React from 'react';

function LinkedIn() {
  return (
    <>
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C4.478 0 0 4.478 0 10s4.478 10 10 10 10-4.478 10-10S15.522 0 10 0zM7.094 15.117H4.66V7.79h2.435v7.327zM5.876 6.79h-.015c-.818 0-1.346-.563-1.346-1.266 0-.72.545-1.266 1.378-1.266.833 0 1.345.547 1.361 1.266 0 .703-.528 1.266-1.378 1.266zm10 8.327H13.44v-3.92c0-.985-.353-1.657-1.234-1.657-.673 0-1.074.454-1.25.891-.064.157-.08.375-.08.594v4.092H8.442s.032-6.64 0-7.327h2.435v1.038c.324-.5.903-1.21 2.195-1.21 1.602 0 2.804 1.047 2.804 3.298v4.201z"
          fill="#BEBEBE"
        />
      </svg>
    </>
  );
}
export default LinkedIn;
