import React from 'react';

function Instagram() {
  return (
    <>
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0c5.522 0 10 4.478 10 10s-4.478 10-10 10S0 15.522 0 10 4.478 0 10 0zm.235 4.258H9.18a29.97 29.97 0 0 0-1.547.034c-.612.028-1.029.125-1.394.267-.383.145-.73.37-1.017.663a2.813 2.813 0 0 0-.663 1.017c-.142.365-.239.782-.267 1.394-.022.483-.03.707-.033 1.547l-.001.37v1.27c.004.84.012 1.064.035 1.548.027.61.125 1.028.266 1.393.145.383.37.73.663 1.017.287.292.634.518 1.017.663.365.142.782.239 1.394.267.483.022.707.03 1.547.033h.37l.45.001h.82c.84-.004 1.064-.012 1.548-.034.61-.028 1.028-.125 1.393-.267a2.936 2.936 0 0 0 1.68-1.68c.142-.365.239-.782.267-1.394.022-.483.03-.707.033-1.547l.001-.37V9.18a28.627 28.627 0 0 0-.034-1.547c-.028-.612-.125-1.029-.267-1.394a2.813 2.813 0 0 0-.663-1.017 2.815 2.815 0 0 0-1.017-.663c-.365-.142-.782-.239-1.394-.266a28.483 28.483 0 0 0-1.547-.034h-.37L10 4.257zm.228 1.035a32.42 32.42 0 0 1 1.857.033c.56.026.864.12 1.067.198.249.092.475.238.66.43.19.184.337.41.43.66.078.202.172.506.197 1.066.025.538.032.741.033 1.857v.927c0 .697-.004 1.038-.012 1.327l-.006.17-.015.36c-.026.56-.12.863-.198 1.066-.193.5-.589.896-1.09 1.09-.202.078-.506.172-1.066.197a29.6 29.6 0 0 1-1.658.033H9.338a42.952 42.952 0 0 1-1.129-.012l-.17-.005-.36-.016c-.56-.025-.863-.119-1.066-.198a1.778 1.778 0 0 1-.66-.43 1.78 1.78 0 0 1-.43-.66c-.078-.202-.172-.506-.197-1.065a42.71 42.71 0 0 1-.015-.36l-.006-.17c-.008-.26-.01-.563-.012-1.129V9.338a29.6 29.6 0 0 1 .033-1.658c.025-.56.119-.864.198-1.067a1.78 1.78 0 0 1 .43-.66c.184-.19.41-.337.66-.43.202-.078.506-.171 1.066-.197l.36-.015.17-.006c.289-.008.63-.011 1.327-.012zM10 7.05a2.949 2.949 0 1 0 0 5.898 2.949 2.949 0 0 0 0-5.898zm0 1.035a1.914 1.914 0 1 1 0 3.828 1.914 1.914 0 0 1 0-3.828zm3.065-1.84a.69.69 0 1 0 0 1.378.69.69 0 0 0 0-1.378z"
          fill="#BEBEBE"
        />
      </svg>
    </>
  );
}
export default Instagram;
