import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getLoggedUserData, logout } from '../../services/auth'
import axios from '../../services/axios'
import { formatDateDDDMMMhmmFromString } from '../../utilities/DateFormat'
import DefaultProfile from "../../assets/DefaultProfile.webp"
import { CiBoxList } from "react-icons/ci"

const TopHeader = ({ profileUpdatedRefresh }) => {
	const [anchorE, setAnchorE] = useState(null)
	const opened = Boolean(anchorE)
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const [notifications, setNotifications] = useState([])
	const [userInfo, setUserInfo] = useState(undefined)
	const [internalTriggerRefresh, setInternalTriggerRefresh] = useState(0)

	const token = localStorage.getItem("token")

	const getNotifications = async () => {
		const { data, status } = await axios.post("/Notification/fetch-notification", { token })

		if (data.success) {
			setNotifications(data.notifications)
			// console.log(notifications)
		}
	}

	const authorInfo = async () => {
		const { data, status } = await axios.post("/Author/info", { token })

		if (data.success) {
			setUserInfo(data.author)
			console.log(data)
			localStorage.setItem("userinfo", JSON.stringify(data.author))
		}
	}

	useEffect(() => {
		authorInfo()
	},[])

	useEffect(() => {
		// Get notification on page load
		getNotifications()

		// Refresh notifications every five seconds
		let interval

		interval = setInterval(() => {
			getNotifications()
		}, 8000)

		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		const storedUser = localStorage.getItem("userinfo")

		if (profileUpdatedRefresh || internalTriggerRefresh) {
			authorInfo()
		}
		else {
			if (storedUser === null) {
				authorInfo()
			} else {
				setUserInfo(JSON.parse(storedUser))
			}
		}
	}, [profileUpdatedRefresh, internalTriggerRefresh])

	// @ts-ignore
	window.FreshworksWidget('hide', 'launcher')

	const handleClic = event => {
		setAnchorE(event.currentTarget)
	}
	const handleClos = () => {
		setAnchorE(null)
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleLogout = () => {
		logout()
		handleClose()
		setInternalTriggerRefresh(prev => prev + 1)
		navigate('/home')
		window.location.reload()
	}

	const handleCheckBooking = bookingId => {
		handleClos()
		navigate(`/booking-property/${bookingId}`)
		window.location.reload()
	}

	const handleSupport = async () => {
		// @ts-ignore
		window.FreshworksWidget('open')
	}

	console.log(userInfo)

	return (
		<>
			{!userInfo && (
				<div className="top-header">
					<Container maxWidth="lg">
						<div className="top-header-wrapper">
							<Link to="/home">
								<img className="app-logo" src="/assets/images/logo.svg" alt="" />
							</Link>
							<ul className="top-header-link-list">
								<li className="top-header-link-list-item">
									<ul className="top-header-link-list-buttons">
										<li>
											<Link to="/login">
												<Button color="primary" variant="outlined">
													Login
												</Button>
											</Link>
										</li>
										<li>
											<Link to="/signup">
												<Button color="primary" variant="contained">
													Sign Up
												</Button>
											</Link>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</Container>
				</div>
			)}
			{userInfo && (
				<div className="app-component-topheader-loggedin">
					<Container maxWidth="lg">
						<div className="app-component-topheader-loggedin-wrapper">
							<Link to="/home">
								<img
									className="topheader-loggedin-logo"
									src="/assets/images/logo.svg"
								/>
							</Link>

							<ul className="topheader-loggedin-list">
								<li style={{ display: "flex", alignItems: "center", gap: "8px", marginRight: "12px" }} className="topheader-loggedin-list-item top-header-support">
									<CiBoxList style={{ fill: "#ff6431" }} />
									<Link style={{ color: "#ff6431", fontSize: "14px", fontWeight: "500", marginBottom: "2px" }} to="/bookings">My Bookings</Link>
								</li>
								<li className="topheader-loggedin-list-item top-header-notification">
									<Tooltip title="Notifications">
										<IconButton
											className="topheader-loggedin-icon-button"
											id="basic-button"
											aria-controls={opened ? 'basic-menu' : undefined}
											aria-haspopup="true"
											aria-expanded={opened ? 'true' : undefined}
											onClick={handleClic}
										>
											<img src="/assets/images/notification-bell.svg" />
										</IconButton>
									</Tooltip>
									<Menu
										className="top-header-notification-menu"
										id="basic-menu"
										anchorEl={anchorE}
										open={opened}
										onClose={handleClos}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
									>
										<div className="top-header-notification-menu-wrapper">
											{notifications.length === 0 && (
												<div className="notification-menu-no-notifications">
													<img src="/assets/images/no-alarm.svg" />
													<h6>No Notifications</h6>
												</div>
											)}
											{notifications.length !== 0 && (
												<>
													<div className="notifications-title">
														<span>Notifications</span>
													</div>
													<div className="notifications-subtitle">
														<span className="notifications-subtitle-text">
															You have
														</span>
														<span className="notifications-subtitle-bold-text">{` ${notifications.length} New Notifications`}</span>
													</div>
													<div className="notification-menu-notifications">
														{notifications.map(notification => {
															return (
																<div
																	key={notification._id}
																	className="notification-box-item"
																>
																	<div className="notification-header">
																		<div className="notification-date">
																			<img
																				className="form-field-icon-append"
																				src="/assets/images/calander-icon.svg"
																			/>
																			<span className="notification-date-text">
																				{formatDateDDDMMMhmmFromString(
																					notification.createdAt,
																				)}
																			</span>
																		</div>
																	</div>
																	<div style={{ fontSize: "14px", color: "#ff6431", marginLeft: "15px", marginTop: "10px", fontWeight: "600" }}>
																		{notification.header}
																	</div>
																	<div className="notification-description">
																		<span>{notification.text}</span>
																	</div>
																</div>
															)
														})}
													</div>
												</>
											)}
										</div>
									</Menu>
								</li>

								<li className="topheader-loggedin-list-item top-header-profile">
									<Button
										className="topheader-loggedin-profile-button"
										id="profile-button"
										aria-controls={open ? 'profile-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
									>
										<img
											className="topheader-loggedin-profile-img"
											src={userInfo.profile_pic ? userInfo.profile_pic : DefaultProfile}
										/>
										<span className="topheader-loggedin-profile-text">
											{`${userInfo?.first_name}`}
											<img style={{ marginLeft: "8px" }} src="/assets/images/down-arrow.svg" />
										</span>
									</Button>
									<Menu
										className="top-header-profile-menu"
										id="profile-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'profile-button',
										}}
									>
										<Link to="/profile">
											<MenuItem onClick={handleClose}>My Profile</MenuItem>
										</Link>
										<Link to="/wishlist">
											<MenuItem onClick={handleClose}>Wishlist</MenuItem>
										</Link>
										<MenuItem onClick={handleClose}>About Us</MenuItem>
										<MenuItem
											className="profile-menu-logout"
											onClick={handleLogout}
										>
											Logout
										</MenuItem>
									</Menu>
								</li>
							</ul>
						</div>
					</Container>
				</div>
			)}
		</>
	)
}
export default TopHeader
