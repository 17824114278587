import { useState } from "react"
import "./css/VerifyOtp.css"
import OTPInput from "react-otp-input"

const VerifyOtp = ({ setPopup, formData, ValidateOtp }) => {
    const [otp, setOtp] = useState("")

    if (formData === undefined) {
        return (
            <div>
                Loading...
            </div>
        )
    }
    
    return (
        <div className="_5bai" onClick={() => setPopup(false)}>
            <div className="container" onClick={e => e.stopPropagation()}>
                <h3>Verify your phone number</h3>
                <p>Please enter one time password sent to +91{formData.phonenumber}</p>
                <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span style={{ margin: "8px", fontWeight: "600" }}> - </span>}
                    renderInput={(props) => <input {...props} />}
                />
                <div className="noontide-moo">
                    <button onClick={() => ValidateOtp(otp)}>Verify</button>
                    <div className="clout-foe" onClick={() => setPopup(false)}>Change phone</div>
                </div>
            </div>
        </div>
    )
}

export default VerifyOtp
