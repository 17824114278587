import Button from '@mui/material/Button'
import TopHeader from '../components/layouts/TopHeader'
import * as React from 'react'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { apiBaseUrl } from '../services/axios'
import { useState } from "react"
import { SyncLoader } from "react-spinners"

const ThankYou = () => {
	const [loading, setLoading] = useState(false)

	const { bookingId } = useParams()

	const token = localStorage.getItem("token")

	const updatesuccess = async () => {
		setLoading(true)

		const { data, status } = await axios.patch(`${apiBaseUrl}api/Booking/update/${bookingId}`, {
			token,
			payment_status: "Success"
		})

		setLoading(false)
	}

	useEffect(() => {
		if (bookingId !== "confirmed") {
			updatesuccess()
		}
	}, [bookingId])

	return (
		<>
			<div className="app-page-thankyou">
				<div className="app-page-thankyou-header">
					<TopHeader />
				</div>
				<div className="app-page-thankyou-wrapper">
					{loading ? (
						<div>
							<SyncLoader color="#ff9774" />
						</div>
					) : (
						<div className="app-page-thankyou-card">
							<img
								className="app-page-thankyou-card-image"
								src="/assets/images/checked.svg"
							/>
							<h1>Thankyou</h1>
							{bookingId === "confirmed" ? <p>Your booking is successful</p> : <p>Your payment is successful</p>}
							<div className="app-page-thankyou-card-btn">
								<Link to="/bookings">
									<Button
										className="app-custom-button"
										color="primary"
										variant="contained"
									>
										{' '}
										View Booking{' '}
									</Button>
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
export default ThankYou
