import { Container, Typography } from "@mui/material"
import Banner from "../components/home/Banner"
import TopHeader from "../components/layouts/TopHeader"
import Footer from "../components/layouts/Footer"


const PrivacyPolicy = () => {
    return (
        <>
            <div className="app-home-wrapper" style={{position:'relative', height:'100vh'}}>
				<div className="app-home-header">
					<TopHeader />
				</div>
                <div style={{width:'82%', backgroundColor:'white', position:'absolute', zIndex:10, top:'20%', left:'8%', boxShadow:' 0 0 10px', borderRadius:'10px'  }}>
                    <div style={{margin:'60px 80px'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'30px'}}>
                            <Typography variant="h4" fontWeight={600}>
                                Privacy Notice
                            </Typography>
                            <Typography variant='body2'>Last updated: 08/02/2023</Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>At Tripizi Trot Private Limited, we prioritize safeguarding your personal information. This privacy notice outlines the details regarding the collection, usage, and protection of your information. It also elucidates your rights and provides guidance on contacting us for queries concerning the handling of your data.</p>
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                            <Typography variant="h6" fontWeight={600}>About Tripizi Trot</Typography>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>In this privacy notice, references to “Tripizi Trot,” “we,” “us,” or “our” pertain to Tripizi Trot Private Limited, a company incorporated under the laws of Singapore.</p>
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                            <Typography variant="h6" fontWeight={600}>Scope of Our Privacy Notice</Typography>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>This notice extends to all individuals interacting with us regarding our hospitality services, whether through our platform, including our website, app, email, or phone, or through third-party channels. We may issue additional privacy notices for specific interactions.</p>
                                <p>If you book accommodations through our platform at properties not branded as Tripizi Trot, the partner property will process your personal data as outlined in their respective privacy notice.</p>
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                            <Typography variant="h6" fontWeight={600}>Collection Methods for Personal Information</Typography>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>We collect personal information directly from you, as well as from travel agents and intermediaries facilitating accommodation bookings on your behalf.</p>
                                <p>If you provide information about other individuals (e.g., co-travelers), ensure that they have reviewed a copy of this privacy policy and consented to your disclosure.</p>
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                        </div>
                    </div>
                </div>
				<div className="app-home-banner" style={{marginBottom:'160px'}}>
                    <div className="app-component-banner app-component-banner-wrapper">
                    </div>
				</div>
            </div>
        </>
    )
}

export default PrivacyPolicy