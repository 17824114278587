import firebase from "firebase"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnFqVzo9q5ZWz_OAd8c32_wB4icdsRvpA",
  authDomain: "hotel-management-631b4.firebaseapp.com",
  projectId: "hotel-management-631b4",
  storageBucket: "hotel-management-631b4.appspot.com",
  messagingSenderId: "582192653072",
  appId: "1:582192653072:web:158e4e935f14586af0bb95"
};

firebase.initializeApp(firebaseConfig)

let auth = firebase.auth()

export { auth, firebase }

