import { IconButton, Popover, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const getNumberOfDays = (checkinDate, checkoutDate) => {
	const from = dayjs(checkinDate)
	const to = dayjs(checkoutDate)
	const diffInDays = to.diff(from, 'day')
	return diffInDays
}

const BookingDetailsResume = ({ bookingInfo, property }) => {
	const navigate = useNavigate()

	const [anchorEl, setAnchorEl] = useState(null)
	const [showAppliedCoupon, setShowAppliedCoupon] = useState(true)

	const open = Boolean(anchorEl)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const numberOfDays = getNumberOfDays(
		bookingInfo?.checkin_date,
		bookingInfo?.checkout_date,
	)

	const getRoomNameById = roomId => {
		const room = property?.HotelRooms.find(
			hotelRoom => hotelRoom._id === roomId,
		)
		if (room) {
			return room.name
		}
		return ''
	}

	// const discount = appliedCoupon.ispercentage ? totalPriceWithoutDiscount * appliedCoupon.discount / 100 : appliedCoupon.discount

	return (
		<div className="app-component-booking-detail-card">
			<div className="app-component-booking-detail-resume-heading">
				<h4>Booking Details</h4>
				<p>{`${property?.title}, ${property?.real_address}`}</p>
				{bookingInfo?.room_ids.map((selectedRoom, index) => (
					<p key={index}>{`${selectedRoom.no_of_rooms} ${selectedRoom.no_of_rooms > 1 ? 'Rooms' : 'Room'
						} - ${getRoomNameById(selectedRoom.hotel_room_id)}`}</p>
				))}
				<p>
					{`${bookingInfo?.no_of_adults} ${bookingInfo?.no_of_adults > 1 ? 'Adults' : 'Adult'
						} - ${bookingInfo?.no_of_chlidrens} ${bookingInfo?.no_of_chlidrens > 1 ? 'Childrens' : 'Children'
						} - ${numberOfDays} ${numberOfDays > 1 ? 'Nights' : 'Night'}`}
				</p>
				{bookingInfo?.extra_price_information.map(extraPrice => (
					<p key={extraPrice._id}>{extraPrice.title}</p>
				))}
			</div>
			{showAppliedCoupon && bookingInfo?.discount > 0 && (
				<div className="booking-detail-applied-coupon">
					<div className="">
						<h5>
							Applied <span>{bookingInfo?.promocode}</span>
						</h5>
						<p>
							you saved <span>{`₹ ${bookingInfo?.discount}`}</span> on coupon
						</p>
					</div>
				</div>
			)}
			<div className="booking-price-details">
				<h3 className="booking-price-details-heading">Price Details</h3>
				<ul className="booking-price-details-list">
					{bookingInfo?.room_ids.map((selectedRoom, index) => (
						<li key={index} className="booking-price-details-list-item">
							<p>{`${selectedRoom.no_of_rooms} ${selectedRoom.no_of_rooms > 1 ? 'Rooms' : 'Room'
								} - ${getRoomNameById(
									selectedRoom.hotel_room_id,
								)} - ${numberOfDays} ${numberOfDays > 1 ? 'Nights' : 'Night'
								}`}</p>
							<span>{`₹ ${selectedRoom.price * selectedRoom.no_of_rooms
								}`}</span>
						</li>
					))}
					{bookingInfo?.extra_price_information.map(
						(selectedExtraPrice, index) => (
							<li key={index} className="booking-price-details-list-item">
								<p>{`${bookingInfo?.no_of_adults + bookingInfo?.no_of_chlidrens
									} ${selectedExtraPrice.title}`}</p>
								<span>{`₹ ${selectedExtraPrice.price *
									(bookingInfo?.no_of_adults + bookingInfo?.no_of_chlidrens)
									}`}</span>
							</li>
						),
					)}
					{property?.HotelBuyerPrices.map(buyerPrice => (
						<li
							key={buyerPrice._id}
							className="booking-price-details-list-item"
						>
							<p>
								{buyerPrice.name}
								<img
									aria-owns={open ? 'mouse-over-popover' : undefined}
									aria-haspopup="true"
									onMouseEnter={handlePopoverOpen}
									onMouseLeave={handlePopoverClose}
									src="/assets/images/info.svg"
								/>
								<Popover
									id="mouse-over-popover"
									sx={{
										pointerEvents: 'none',
									}}
									open={open}
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'center',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									onClose={handlePopoverClose}
									disableRestoreFocus
								>
									<h6 className="popover-text">{buyerPrice.content}</h6>
								</Popover>
							</p>
							<span>{`₹ ${buyerPrice.is_price_per_person
									? buyerPrice.price *
									(bookingInfo?.no_of_adults + bookingInfo?.no_of_chlidrens)
									: buyerPrice.price
								}`}</span>
						</li>
					))}
					<li className="booking-price-details-list-item booking-price-details-list-item-total">
						<p>Total payable Amount</p>
						<span>{`₹ ${bookingInfo?.total_paid}`}</span>
					</li>
				</ul>
			</div>
			<div className="booking-price-details-conditions">
				<ul>
					{property?.HotelPolicy.map(policy => (
						<Tooltip key={policy._id} title={policy.content}>
							<li>{policy.name}</li>
						</Tooltip>
					))}
				</ul>
			</div>
		</div>
	)
}

export default BookingDetailsResume
