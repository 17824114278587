import Container from '@mui/material/Container';
import React, { useState } from 'react';
import Banner from '../components/home/Banner';
import DiscoverSlider from '../components/home/DiscoverSlider';
import MainCta from '../components/home/MainCta';
import MobileAppPromotion from '../components/home/MobileAppPromotion';
import TopPlaces from '../components/home/TopPlaces';
import Footer from '../components/layouts/Footer';
import TopHeader from '../components/layouts/TopHeader';
import { useEffect } from 'react';
import axios from '../services/axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';

const Home = () => {
  const [featured, setFeatured] = useState([]);

  const getFeatured = async () => {
    const { data, status } = await axios.get('/Hotel/featured');

    console.log({ data });
    if (data.success) {
      setFeatured(data.featured);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(location => {
      console.log(location);

      localStorage.setItem(
        'location',
        JSON.stringify({
          latitude: location.coords && location.coords.latitude,
          longitude: location.coords && location.coords.longitude,
        }),
      );
    });
  }, []);

  useEffect(() => {
    getFeatured();
  }, []);

  return (
    <>
      <div className="app-home-wrapper">
        <div className="app-home-header">
          <TopHeader />
        </div>
        <div className="app-home-banner">
          <Banner />
        </div>
        <div className="app-home-top-places">
          <Container maxWidth="lg">
            <div className="app-home-top-places-wrapper">
              <div className="app-home-top-places-heading">
                <h1>
                  Start Your Escape Now:
                  <span> Book Your Exciting Journey!</span>
                </h1>
                <p>
                  Discover incredible experiences and amazing deals! Don't miss
                  out! Discover the featured Hotels
                </p>
              </div>
              <div className="app-home-top-places-content">
                <TopPlaces />
              </div>
            </div>
          </Container>
        </div>
        <div className="app-home-discover">
          <Container maxWidth="lg">
            <div className="app-home-top-places-wrapper">
              <div className="app-home-top-places-heading">
                <h1>
                  Unveiled Top Hotels <span>You Don't Want to Miss</span>
                </h1>
                <p>
                  Explore our curated collection of exceptional places to stay.
                </p>
              </div>
            </div>
          </Container>
          <div className="app-home-discover-slider">
            <div className="app-component-discovery-slider">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                slidesPerView={3.7}
                spaceBetween={40}
                grabCursor={true}
                wrapper-start={20}
                className="mySwiper"
              >
                {featured.map((discoverArea, index) => {
                  console.log({ discoverArea });
                  const link = `/properties-inner/${discoverArea._id}?locationId=${discoverArea.location_id}&locationTitle=Hyderabad&from=null&to=null&adults=null&childrens=null&rooms=null`;
                  return (
                    <SwiperSlide key={index}>
                      <Link to={link}>
                        <div className="app-component-top-places-card">
                          <img
                            src={
                              discoverArea.banner_image
                                ? discoverArea.banner_image.includes(
                                    'amazonaws.com',
                                  )
                                  ? discoverArea.banner_image
                                  : 'https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png'
                                : 'https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png'
                            }
                          />
                          <div className="app-component-top-places-card-content">
                            <h3>{discoverArea.title}</h3>
                            <p>{discoverArea.description}</p>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="app-home-discover">
          <Container maxWidth="lg">
            <div className="app-home-top-places-wrapper">
              <div className="app-home-top-places-heading">
                <h1>
                  Unleash Your Urban Spirit:
                  <span> Discover Your Dream City Break</span>
                </h1>
                <p>
                  Find the perfect city escape for every taste and budget. Book
                  Now
                </p>
              </div>
            </div>
          </Container>
          <div className="app-home-discover-slider">
            <DiscoverSlider />
          </div>
        </div>
        <div className="app-mobile-promotion">
          <MobileAppPromotion />
        </div>
        <div className="app-home-main-cta">
          <MainCta />
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Home;
