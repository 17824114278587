import { Container, Typography } from "@mui/material"
import Banner from "../components/home/Banner"
import TopHeader from "../components/layouts/TopHeader"

const polocyContent = (
    <ul style={{display:'flex', flexDirection:'column', gap:'20px'}}>
        <li>All guests must present original and valid photo identification at the time of check-in, such as a National ID for GCC residents or passport.</li>
        <li>The primary guest must be at least 18 years of age to be able to check-in to the hotel.</li>
        <li>It is mandatory for guests to present valid photo identification at the time of check-in.</li>
        <li>Stay of 1 child up to 5 years of age is complementary without the use of extra bed. Breakfast charges may be applicable for the child.</li>
        <li>Pets are not allowed in the hotel premises.</li>
        <li>Should any action by a guest be deemed inappropriate by the hotel, or if any inappropriate behavior is brought to the attention of the hotel, the hotel reserves the right, after the allegations have been investigated, to take action against the guest.</li>
        <li>A discounted booking cannot be modified. In case of early check-out for such a booking, no amount will be refunded.</li>
        <li>Guests may be contacted closer to their check-in date to confirm the arrival status or arrival time through calls or messages. In case, we do not receive a response from the guest after multiple attempts, the booking may be put on hold or canceled. In case of availability, Tripizi Trot Private Limited will try to reinstate your booking when you contact us back or make a payment through our multitude of payment options.</li>
        <li>As we continue to strive to improve our services, Tripizi Trot Private Limited may reach out to guests to get feedback of their experience through calls or messages.</li>
    </ul>
)


const GuestPolicy = () => {
    return (
        <>
            <div className="app-home-wrapper" style={{position:'relative', height:'100vh'}}>
				<div className="app-home-header">
					<TopHeader />
				</div>
                <div style={{width:'82%', backgroundColor:'white', position:'absolute', zIndex:10, top:'20%', left:'8%', boxShadow:' 0 0 10px', borderRadius:'10px'  }}>
                    <div style={{margin:'60px 80px'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'30px'}}>
                            <Typography variant="h4" fontWeight={600}>
                                Guest Policy
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontSize={'17px'}>
                                {polocyContent}
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                        </div>
                    </div>
                </div>
				<div className="app-home-banner">
                    <div className="app-component-banner app-component-banner-wrapper">
                    </div>
				</div>
            </div>
        </>
    )
}

export default GuestPolicy