import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios, { apiBaseUrl } from '../../services/axios';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

const DiscoverSlider = () => {
  const [discoverAreas, setDiscoverAreas] = useState(null);

  const getDiscoverAreas = async () => {
    const { data, status } = await axios.get('Areas/list');
    if (status === 200 || status === 201) {
      setDiscoverAreas(data?.results);
    }
  };
  useEffect(() => {
    getDiscoverAreas();
  }, []);

  return (
    <>
      {discoverAreas && (
        <div className="app-component-discovery-slider">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            slidesPerView={3.7}
            spaceBetween={40}
            grabCursor={true}
            wrapper-start={20}
            className="mySwiper"
          >
            {discoverAreas &&
              discoverAreas.map((discoverArea, index) => (
                <SwiperSlide key={index}>
                  <Link to={`/properties-listing?locationId=${discoverArea.location_id}&area_id=${discoverArea._id}`}>
                    <div className="app-component-top-places-card">
                      <img src={discoverArea.icon ? (discoverArea.icon.includes("amazonaws.com") ? discoverArea.icon : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png") : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png"} />
                      <div className="app-component-top-places-card-content">
                        <h3>{discoverArea.title}</h3>
                        <p>{discoverArea.description}</p>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </>
  );
};
export default DiscoverSlider;
