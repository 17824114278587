import { Button } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios, { apiBaseUrl } from '../services/axios'

const CouponCard = ({
	coupon,
	totalPrice,
	totalPriceWithoutDiscount,
	adults,
	childrens,
	coupons,
	appliedCoupon,
	setAppliedCoupon,
	setShowAppliedCoupon,
	handleCloseModal
}) => {
	const [checkedCoupon, setCheckedCoupon] = useState(null)

	const handleApplyCoupon = () => {
		if (totalPriceWithoutDiscount < coupon.amount) {
			toast.error("Coupon exceeds total amount")
			return
		}

		setAppliedCoupon({
			id: coupon._id,
			code: coupon.code,
			ispercentage: coupon.discount_type === "Percent" ? true : false,
			discount: coupon.amount
		})
		
		toast.success("Coupon applied success")
		setShowAppliedCoupon(true)
		handleCloseModal()
	}

	const discount = coupon.discount_type === "Percent" ? totalPriceWithoutDiscount * coupon.amount / 100 : coupon.amount
	
	return (
		<div className="app-component-coupon">
			<div className="coupon-card-graphic"></div>
			<div className="app-component-coupon-wrapper">
				<div className="app-component-coupon-sponcer-logo">
					<img src="https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/visualsofdana-T5pL6ciEn-I-unsplash.jpg" />
				</div>
				<div className="app-component-coupon-discount">
					<h1>{coupon?.name}</h1>
					<p>{`You will save ₹${discount} using this coupon`}</p>
				</div>
			</div>
			<div className="app-component-coupon-stripped-line">
				<span></span>
			</div>
			<div className="app-component-coupon-info">
				<p>Use coupon code</p>
				<h5>{coupon?.code}</h5>
			</div>
			<div className="app-component-coupon-apply-btn">
				<Button onClick={handleApplyCoupon}>Apply</Button>
			</div>
		</div>
	)
}
export default CouponCard
