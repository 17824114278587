import React from 'react';

function Facebook() {
  return (
    <>
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm2.487 10.352H10.86v5.799H8.45v-5.799H7.302v-2.05H8.45V6.978c0-.95.451-2.433 2.433-2.433l1.787.007v1.99h-1.297c-.211 0-.511.105-.511.558v1.204h1.837l-.211 2.05z"
          fill="#BEBEBE"
        />
      </svg>
    </>
  );
}
export default Facebook;
