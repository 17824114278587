import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import BookingProperty from '../pages/BookingProperty';
import Bookings from '../pages/Bookings';
import ChangePassword from '../pages/ChangePassword';
import EditProfile from '../pages/EditProfile';
import EmailSent from '../pages/EmailSent';
import ForgotPassword from '../pages/ForgotPassword';
import Home from '../pages/Home';
import Payment from '../pages/Payment';
import Profile from '../pages/Profile';
import PropertiesListing from '../pages/PropertiesListing';
import PropertyInner from '../pages/PropertyInner';
import ThankYou from '../pages/ThankYou';
import Wishlist from '../pages/Wishlist';
import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { isAuthenticated } from '../services/auth';
import ChangePassword2 from '../pages/ChangePassword2';
import AboutPage from '../pages/AboutPage';
import TermsandConditions from '../pages/TermsandConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import CancellationOptions from '../pages/CancellationOptions';
import GuestPolicy from '../pages/GuestPolicy';
import ContactPage from '../pages/ContactPage';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const authenticated = isAuthenticated();
  const returnTo =
    location.pathname + window.location.search + window.location.hash;
  const encodedReturnTo = encodeURIComponent(returnTo);

  return (
    <>
      {authenticated && children}
      {!authenticated && (
        <Navigate
          to={{
            pathname: '/login',
            search: `?returnTo=${encodedReturnTo}`,
          }}
          state={{
            from: location.pathname,
          }}
        />
      )}
    </>
  );
};

export const Router = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="home" element={<Home />} />
          <Route path="properties-listing" element={<PropertiesListing />} />
          <Route path="properties-inner/:id" element={<PropertyInner />} />
          <Route path="about-page" element={<AboutPage/>} />
          <Route path="TermsandConditions" element={<TermsandConditions/>} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="CancellationOptions" element={<CancellationOptions/>} />
          <Route path="ContactPage" element={<ContactPage/>} />
          <Route path="GuestPolicy" element={<GuestPolicy/>} />
          <Route
            path="booking-property/:bookingId"
            element={<PrivateRoute>{<BookingProperty />}</PrivateRoute>}
          />
          <Route
            path="payment"
            element={<PrivateRoute>{<Payment />}</PrivateRoute>}
          />
          <Route
            path="thankyou/:bookingId"
            element={<PrivateRoute>{<ThankYou />}</PrivateRoute>}
          />
          <Route
            path="bookings"
            element={<PrivateRoute>{<Bookings />}</PrivateRoute>}
          />
          <Route
            path="wishlist"
            element={<PrivateRoute>{<Wishlist />}</PrivateRoute>}
          />
          <Route
            path="profile"
            element={<PrivateRoute>{<Profile />}</PrivateRoute>}
          />
          <Route
            path="edit-profile"
            element={<PrivateRoute>{<EditProfile />}</PrivateRoute>}
          />
          <Route
            path="change-password"
            element={<PrivateRoute>{<ChangePassword />}</PrivateRoute>}
          />
          <Route
            path="account/reset-password/:hash"
            element={<ChangePassword2 />}
          />
          <Route path="email-sent" element={<EmailSent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
