import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TopHeader from '../components/layouts/TopHeader';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from '../services/axios';
import { changePasswordSchema } from '../services/validations';

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });
  const navigate = useNavigate();
  const onSubmit = async formData => {
    const { status } = await axios.post(`Author/changePassword`, formData);
    if (status === 200 || status === 201) {
      toast.success('Password changed successfully');
      navigate('/profile');
    }
  };

  const [searchParams] = useSearchParams();

  const login = searchParams.get('login');
  console.log(login);

  return (
    <>
      <div className="login-bg">
        <div className="">
          <TopHeader />
        </div>
        <Container>
          <div className="login-card-wrapper">
            <div className="login-card">
              <div className="login-card-content">
                <h2>Change Password</h2>
                <p>Enter your email to send the link</p>
              </div>
              <div className="login-card-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    <li>
                      <div className="field-wrapper">
                        <div className="app-form-field">
                          <input
                            type={'password'}
                            placeholder="Enter your Old Password"
                            {...register('old_password')}
                          />
                          <img
                            className="input-append-left"
                            src="/assets/images/lock.svg"
                          />
                        </div>
                        {errors.old_password && (
                          <div className="error-message">
                            {errors.old_password.message}
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="field-wrapper">
                        <div className="app-form-field">
                          <input
                            type={'password'}
                            placeholder="Enter your New Password"
                            {...register('new_password')}
                          />
                          <img
                            className="input-append-left"
                            src="/assets/images/lock.svg"
                          />
                        </div>
                        {errors.new_password && (
                          <div className="error-message">
                            {errors.new_password.message}
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="field-wrapper">
                        <div className="app-form-field">
                          <input
                            type={'password'}
                            placeholder="Confirm your Password"
                            {...register('confirm_new_password')}
                          />
                          <img
                            className="input-append-left"
                            src="/assets/images/lock.svg"
                          />
                        </div>
                        {errors.confirm_new_password && (
                          <div className="error-message">
                            {errors.confirm_new_password.message}
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="app-form-button">
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ChangePassword;
