import orderByDistance from "geolib/es/orderByDistance"
import getDistance from "geolib/es/getDistance"
import { Pagination } from '@mui/material'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from '../services/axios'
import Filter, { MAX_PRICE_RANGE, MIN_PRICE_RANGE } from '../components/Filter'
import Banner from '../components/home/Banner'
import Footer from '../components/layouts/Footer'
import TopHeader from '../components/layouts/TopHeader'
import PropertyCard from '../components/PropertyCard'

const PAGE_SIZE = 12

const prepareApiSearchParams = (realPage, filtering) => {
	const apiSearchParams = new URLSearchParams()
	apiSearchParams.append('page', realPage.toString())
	apiSearchParams.append('page_size', PAGE_SIZE.toString())
	apiSearchParams.append('location_id', filtering.locationId)
	apiSearchParams.append('no_of_rooms', filtering.rooms)
	apiSearchParams.append('checkin_at', filtering.from)
	apiSearchParams.append('checkout_at', filtering.to)
	apiSearchParams.append('no_of_guests', filtering.adults)
	apiSearchParams.append('no_of_childrens', filtering.childrens)
	apiSearchParams.append('sort_by', filtering.sortBy)
	apiSearchParams.append('from_price', filtering.fromPrice)
	apiSearchParams.append('to_price', filtering.toPrice)
	filtering.areaId && apiSearchParams.append('area_id', filtering.areaId)
	if (filtering.services.length > 0) {
		apiSearchParams.append('services', filtering.services.toString())
	}
	if (filtering.propertyTypes.length > 0) {
		apiSearchParams.append('propertytypes', filtering.propertyTypes.toString())
	}
	if (filtering.amenities.length > 0) {
		apiSearchParams.append('amenities', filtering.amenities.toString())
	}
	return apiSearchParams
}

const PropertiesListing = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [page, setPage] = useState(1)

	const searchParamLocationId = searchParams.get('locationId')
	const searchParamLocationTitle = searchParams.get('locationTitle')
	const searchParamFrom = searchParams.get('from')
	const searchParamTo = searchParams.get('to')
	const searchParamAdults = searchParams.get('adults')
	const searchParamChildrens = searchParams.get('childrens')
	const searchParamRooms = searchParams.get('rooms')
	const searchParamSortBy = searchParams.get('sortBy')
	const searchFromPrice = searchParams.get('fromPrice')
	const searchToPrice = searchParams.get('toPrice')
	const searchParamServices = searchParams.get('services')
	const searchParamPropertyTypes = searchParams.get('propertyTypes')
	const searchParamAmenities = searchParams.get('amenities')
	const searchParamAreaId = searchParams.get('area_id')

	const userLocation = localStorage.getItem("location") && JSON.parse(localStorage.getItem("location"))



	const [filtering, setFiltering] = useState({
		locationId: searchParamLocationId,
		rooms: searchParamRooms,
		from: searchParamFrom,
		to: searchParamTo,
		adults: searchParamAdults,
		childrens: searchParamChildrens,
		sortBy: searchParamSortBy ?? 'rating',
		fromPrice: searchFromPrice ?? MIN_PRICE_RANGE.toString(),
		toPrice: searchToPrice ?? MAX_PRICE_RANGE.toString(),
		services: searchParamServices ? searchParamServices.split(',') : [],
		propertyTypes: searchParamPropertyTypes
			? searchParamPropertyTypes.split(',')
			: [],
		amenities: searchParamAmenities ? searchParamAmenities.split(',') : [],
		areaId: searchParamAreaId
	})

	const [properties, setProperties] = useState([])

	const populateDistance = (properties) => {
		const nolocationproperties = []
		const locationfixed = []

		if (userLocation) {
			properties.forEach(item => {
				const copy = JSON.parse(JSON.stringify(item))

				if (item.map_latitude && item.map_longitude) {
					copy.latitude = copy.map_latitude
					copy.longitude = copy.map_longitude

					const distance = getDistance(userLocation, copy)

					copy.distanceFromUser = distance

					locationfixed.push(copy)
				}
				else {
					nolocationproperties.push(copy)
				}
			})

			const fixedPlusInvalids = [...locationfixed, ...nolocationproperties]

			return fixedPlusInvalids
		}

		return properties
	}

	const handleSortNearest = (properties) => {
		const nolocationproperties = []
		const locationfixed = []

		if (userLocation) {
			properties.forEach(item => {
				const copy = JSON.parse(JSON.stringify(item))

				if (item.map_latitude && item.map_longitude) {
					copy.latitude = copy.map_latitude
					copy.longitude = copy.map_longitude

					locationfixed.push(copy)
				}
				else {
					nolocationproperties.push(copy)
				}
			})

			// Sort properties containing location
			const sortedProperties = orderByDistance(userLocation, locationfixed)

			const sortedPlusInvalids = [...sortedProperties, ...nolocationproperties]

			return sortedPlusInvalids
		}

		return properties
	}

	// const handleSortTitle = (properties) => {
	// 	properties = properties.sort().reverse()
	// 	return properties
	// }
	const  handleSortTitle = (data) =>{
		const sortedData = data.sort((a, b) => a.title.localeCompare(b.title));
		return sortedData;
	}


	const getProperties = async () => {
		const realPage = page - 1
		const apiSearchParams = prepareApiSearchParams(realPage, filtering)
		const { data, status } = await axios.get(
			`Hotel/list?${apiSearchParams.toString()}`,
		)

		if (status === 200 || status === 201) {
			let populated = data?.results

			// Populate distance from user's location
			if (userLocation) {
				populated = populateDistance(populated)
			}

			// Handle frontend filtering
			if (filtering.sortBy === "nearest") {
				populated = handleSortNearest(populated)
			}
			if(filtering.sortBy === "title"){
				populated = handleSortTitle(populated);
			}



			console.log({ filtering })

			setProperties(populated)
		}
	}

	useEffect(() => {	
		getProperties()
	}, [filtering, page])

	useEffect(() => {
		setFiltering(oldFiltering => ({
			...oldFiltering,
			locationId: searchParamLocationId,
			rooms: searchParamRooms,
			from: searchParamFrom,
			to: searchParamTo,
			adults: searchParamAdults,
			childrens: searchParamChildrens,
			areaId: searchParamAreaId,
		}))
	}, [
		searchParamLocationId,
		searchParamFrom,
		searchParamTo,
		searchParamAdults,
		searchParamChildrens,
		searchParamRooms,
		searchParamAreaId,
	])

	const handleSortChange = event => {
		resetPage()
		setFiltering(oldFiltering => ({
			...oldFiltering,
			sortBy: event.target.value,
		}))
		setSearchParams(searchParams => {
			searchParams.set('sortBy', event.target.value)
			return searchParams
		})
	}

	const resetPage = () => {
		setPage(1)
		setSearchParams(searchParams => {
			searchParams.set('page', '0')
			return searchParams
		})
	}

	const handlePageChange = (_event, value) => {
		setPage(value)
	}

	const handleAddFavourite = async propertyId => {
		await axios.post(`/Favourite/create`, {
			hotel_id: propertyId,
		})
	}

	console.log({ properties })

	// useEffect(() => {},[areaId,locationId])

	return (
		<>
			<div className="app-page-properties-listing">
				<div className="app-home-header">
					<TopHeader />
				</div>
				<div className="app-home-banner">
					<Banner />
				</div>
				<Container maxWidth="lg">
					<div className="app-page-properties-listing-heading">
						{searchParamLocationTitle && (
							<h2>
								{properties.length} Results found in{' '}
								<span>{searchParamLocationTitle}</span>
							</h2>
						)}
						{!searchParamLocationTitle && (
							<h2>{properties.length} Results found</h2>
						)}
						<div className="app-page-properties-listing-heading-select">
							<FormControl fullWidth>
								<Select
									displayEmpty
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={filtering.sortBy}
									onChange={e => handleSortChange(e)}
								>
									<MenuItem value="rating">Sort by Rating</MenuItem>
									<MenuItem value="price">Sort by Price</MenuItem>
									<MenuItem value="title">Sort by Title</MenuItem>
									<MenuItem value="nearest">Sort by Nearest</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</Container>
				<div className="app-page-properties-listing-content">
					<Container maxWidth="lg">
						<div className="app-page-properties-listing-filter">
							<Grid container spacing={3}>
								<Grid item xs={12} md={3} lg={3}>
									<div className="">
										<Filter
											filtering={filtering}
											setFiltering={setFiltering}
											setSearchParams={setSearchParams}
											resetPage={resetPage}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={9} lg={9}>
									<div className="app-page-properties-listing-properties">
										<Grid container spacing={2}>
											{properties.slice(
												(page - 1) * PAGE_SIZE,
												((page - 1) * PAGE_SIZE) + PAGE_SIZE
											)
											.filter(property => property.status === "Live").map(property =>
												 (
										
												<Grid key={property._id} item xs={12} md={6} lg={4}>
													<PropertyCard
														property={property}
														filtering={filtering}
														locationId={searchParamLocationId}
														locationTitle={searchParamLocationTitle}
														handleAddFavourite={handleAddFavourite}
													/>
												</Grid>
											))}
										</Grid>
									</div>
								</Grid>
							</Grid>
						</div>
						{properties.length > PAGE_SIZE && (
							<div style={{ marginTop: "40px", marginLeft: "290px", display: "flex", justifyContent: "center" }} className="app-page-properties-listing-pagination">
								<Pagination
									count={Math.ceil(properties.length / PAGE_SIZE)}
									page={page}
									onChange={handlePageChange}
								/>
							</div>
						)}
					</Container>
				</div>
				{/* {!isAuthenticated() && (
          <div className="">
            <SubscribeCta />
          </div>
        )} */}
				<div className="">
					<Footer />
				</div>
			</div>
		</>
	)
}

export default PropertiesListing
