import Container from '@mui/material/Container';
import TopHeader from '../components/layouts/TopHeader';
import React from 'react';
import { useLocation } from 'react-router';

const EmailSent = () => {
  const location = useLocation();
  return (
    <>
      <div className="login-bg">
        <div className="">
          <TopHeader />
        </div>
        <Container>
          <div className="login-card-wrapper">
            <div className="app-page-email-card">
              <img src="/assets/images/mail-sent.svg" />
              <h1>Email sent successfully</h1>
              <p>We&apos;ve sent a password reset email to</p>
              <span>{location.state.email}</span>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default EmailSent;
