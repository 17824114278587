import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Link } from 'react-router-dom';
function MobileAppPromotion() {
  return (
    <>
      <div className="app-component-mobile-app-promo">
        <Container maxWidth="lg">
          <div className="mobile-app-promo-wrapper">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <div className="mobile-app-promo-wrapper">
                  <h1>
                    Secret Deals Revealed:
                    <span> Download Our App for Unmissable Offers</span>
                  </h1>
                  <p>
                    Find amazing offers on hotels, flights & more, right at your
                    fingertips. Reserve your place at once!
                  </p>
                  <ul>
                    <li>
                      <Link to={''}>
                        <Button>
                          <img src="/assets/images/google-play.png" />
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <Link to={''}>
                        <Button>
                          <img src="/assets/images/download-on-the-app-store.svg" />
                        </Button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
            <img
              className="mobile-app-promo-img"
              src="/assets/images/mobile-app-promo.png"
            />
          </div>
        </Container>
      </div>
    </>
  );
}
export default MobileAppPromotion;
