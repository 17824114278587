import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Container } from '@mui/system';
import TopHeader from '../components/layouts/TopHeader';
import * as React from 'react';
import { Link } from 'react-router-dom';
import BookingDetail from '../components/BookingDetails';

function Payment() {
  const [age, setAge] = React.useState('');

  const handleChange = event => {
    setAge(event.target.value);
  };
  return (
    <>
      <div className="app-page-payment">
        <div className="app-page-payment-header">
          <TopHeader />
        </div>
        <div className="app-page-payment-wrapper">
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={7}>
                <div className="app-page-payment-person-details">
                  <div className="app-page-payment-person-detail-card">
                    <h3>Your details</h3>
                    <ul>
                      <li>
                        <p>Full Name</p>
                        <span>Chaitanya Yalamnchili</span>
                      </li>
                      <li>
                        <p>Email</p>
                        <span>ykchaitu@gmail.com</span>
                      </li>
                      <li>
                        <p>Phone</p>
                        <span>+91 9999999999</span>
                      </li>
                    </ul>
                    <div className="payment-person-detail-card-edit">
                      <Link to={''}>
                        <Button color="primary">Edit</Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="app-page-payment-action">
                  <h3>Complete Booking</h3>
                  <div className="app-page-payment-action-wrapper">
                    <div className="app-page-payment-action-menu">
                      <ul>
                        <li>
                          <Button className="active">Credit/Debit Cards</Button>
                        </li>
                        <li>
                          <Button>Pay Using UPI</Button>
                        </li>
                        <li>
                          <Button>Wallets</Button>
                        </li>
                        <li>
                          <Button>Net banking</Button>
                        </li>
                      </ul>
                    </div>
                    <form className="app-page-payment-action-menu-form">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <div className="payment-action-menu-form-field">
                            <input type={'number'} placeholder="Card number" />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="payment-action-menu-form-field">
                            <input
                              type={'text'}
                              placeholder="Card holder name"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div className="payment-action-menu-form-field">
                            <div className="payment-action-menu-form-field-validthr">
                              <p>Valid Thru</p>
                              <div className="payment-action-menu-form-field-validthr-month">
                                <FormControl fullWidth>
                                  <Select
                                    displayEmpty
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    onChange={handleChange}
                                    renderValue={selected => {
                                      if (selected.length === 0) {
                                        return <span>MM</span>;
                                      }

                                      return selected;
                                    }}
                                  >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="payment-action-menu-form-field-validthr-year">
                                <FormControl fullWidth>
                                  <Select
                                    displayEmpty
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    onChange={handleChange}
                                    renderValue={selected => {
                                      if (selected.length === 0) {
                                        return <span>YYYY</span>;
                                      }

                                      return selected;
                                    }}
                                  >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="payment-action-menu-form-field">
                            <input type={'number'} placeholder="CVV" />
                          </div>
                        </Grid>
                      </Grid>
                      <div className="payment-action-menu-form-button">
                        <Link to="/thankyou">
                          <Button
                            className="app-custom-button"
                            color="primary"
                            variant="contained"
                          >
                            Pay Now
                          </Button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="app-page-booking-property-bookingdetails">
                  <BookingDetail />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}
export default Payment;
