import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import axios from '../../services/axios'
import { setLocalStorageItem } from '../../services/storageHandling'
import TopHeader from '../../components/layouts/TopHeader'
import { loginSchema } from '../../services/validations'
import { toast } from "react-toastify"
import { useState } from "react"
import VerifyOtp from "../../components/VerifyOtp"
import { firebase, auth } from "../../config/firebase"
import { BsPhone } from "react-icons/bs"
import decode from "jwt-decode"
import { IoEyeOutline } from "react-icons/io5";
import {IoEyeOffOutline} from "react-icons/io5"
const Login = () => {
	const [verifyOtpPopup, setVerifyOtpPopup] = useState(false)
	const [submittedForm, setSubmittedForm] = useState(undefined)
	const [phonecode, setPhonecode] = useState("+91")
	const [otpVerifier, setOtpVerifier] = useState(undefined)
	const [loginWithPhone, setLoginWithPhone] = useState(false)
	const [searchParams] = useSearchParams()

	const returnTo = searchParams.get('returnTo')

	const {
		register,
		handleSubmit,
		formState: { errors },
	  } = useForm({
		resolver: yupResolver(
		  loginWithPhone ? phoneValidationSchema : emailValidationSchema
		),
	  });

	const navigate = useNavigate()

	const sendAuthToBackend = async (result) => {
		const { data, status } = await axios.post('Author/otp-verified', {
			phonenumber: submittedForm.email,
			result
		})

		if (data.success) {
			toast.success('Login success')
			setLocalStorageItem('token', data.token)

			const decoded = decode(data.token)

			setLocalStorageItem('logged_user_data', JSON.stringify(decoded.user))

			if (returnTo) {
				navigate(`${returnTo}`)
			} else {
				navigate('/home')
			}
		}
		else {
			toast.error("Something went wrong")
			console.log({ data })
		}
	}

	function setuprecaptcha() {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			size: 'invisible',
			callback: function (response) {
				console.log("recature resolved")
				this.onSignInSubmit()
			}
		})
	}

	// Sent OTP
	const signin = async (formattedPhone, unFormattedPhone) => {
		if (formattedPhone === "" || formattedPhone.length < 10) return

		const { data, status } = await axios.post('Author/phone-exists', { phonenumber: unFormattedPhone })

		if (data.success === false) {
			toast.error(data.message)
			return
		}

		var verify = window.recaptchaVerifier

		auth.signInWithPhoneNumber(formattedPhone, verify).then((result) => {
			setOtpVerifier(result)
			setVerifyOtpPopup(true)
		})
			.catch((err) => {
				alert(err)
				window.location.reload()
			})
	}

	// Validate OTP
	const ValidateOtp = (otp) => {
		if (otp === null || otpVerifier === null || otpVerifier === undefined)
			return
		otpVerifier.confirm(otp).then((result) => {
			// success
			sendAuthToBackend(result)
		}).catch((err) => {
			alert("Wrong code")
		})
	}

	const onSubmit = async formData => {
		formData.phonenumber = formData.email

		const email = formData && formData.email

		if (email && email.includes("@")) {
			const { data, status } = await axios.post('Author/login', formData)

			if (status === 200 || status === 201) {
				if (data && data.results.restricted) {
					toast.error("You have been blocked")
					return
				}

				setLocalStorageItem('token', data?.Token)
				setLocalStorageItem('refresh_token', data?.refreshToken)
				setLocalStorageItem('logged_user_data', JSON.stringify(data?.results))
				if (returnTo) {
					navigate(`${returnTo}`)
				} else {
					navigate('/home')
				}
			}
		}
		else if (email && (isNaN(email) === true || email.length < 10)) {
			toast.error("Invalid phone number")
		}
		else {
			setSubmittedForm(formData)
			signin(`${phonecode}${formData.phonenumber}`, formData.phonenumber)
		}
	}

	useEffect(() => {
		setuprecaptcha()
	}, [])
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(prevState => !prevState);
	};
	return (
		<>
			<div className="login-bg">
				<div id="recaptcha-container"></div>
				{verifyOtpPopup ? <VerifyOtp setPopup={setVerifyOtpPopup} formData={submittedForm} ValidateOtp={ValidateOtp} /> : undefined}
				<div className="">
					<TopHeader />
				</div>
				<Container>
					<div className="login-card-wrapper">
						<div className="login-card">
							<div className="login-card-content">
								<h2>Login</h2>
								<p>Lorem Ipsum is simply dummy text of the printing</p>
							</div>
							<div className="login-card-form">
								<form onSubmit={handleSubmit(onSubmit)}>
									<ul>
										<li>
											<div className="field-wrapper">
												<div className="app-form-field">
													<input
														type={loginWithPhone ? "number" : "text"}
														placeholder={loginWithPhone ? "Enter your phone number" : "Enter your Email"}
														{...register('email')}
													/>
													{loginWithPhone ? <BsPhone className="input-append-left email-icon" /> : <img
														className="input-append-left email-icon"
														src="/assets/images/email.svg"
													/>}
												</div>
												{errors.email && (
													<div className="error-message">
														{errors.email.message}
													</div>
												)}
											</div>
										</li>

										{loginWithPhone === false && (
											<li>
												<div className="field-wrapper">
													<div className="app-form-field">
														<input
															type={showPassword ? 'text' : 'password'}
															placeholder="Enter your Password"
															{...register('password')}
														/>
														<img
															className="input-append-left"
															src="/assets/images/lock.svg"
														/>
														<div>
															<span
																className="input-append-right"
																onClick={togglePasswordVisibility}
																style={{ cursor: 'pointer' }}
															>
																{showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
															</span>
														</div>
													</div>
													{errors.password && (
														<div className="error-message">
															{errors.password.message}
														</div>
													)}
												</div>
											</li>
										)}
										<div onClick={() => setLoginWithPhone(prev => !prev)} style={{ marginTop: "10px", fontSize: "13px", color: "#ff6431", cursor: "pointer", textDecoration: "underline" }}>
											Login with {loginWithPhone ? "e-mail address" : "phone number"}
										</div>
										<li>
											<div className="app-form-button">
												<Button
													type="submit"
													color="primary"
													variant="contained"
												>
													Login
												</Button>
											</div>
										</li>
									</ul>
								</form>
								<div className="login-card-form-bottom">
									<Link to="/forgot-password">
										<span className="app-forgot-pass-text">
											Forgot Password ?
										</span>
									</Link>
									{/* <div className="login-card-form-bottom-or-divider">
                    <p>Or</p>
                  </div>
                  <div className="login-card-form-bottom-button">
                    <Button variant="contained">
                      <img src="/assets/images/google.svg" />
                      <span>Continue with google</span>
                    </Button>
                  </div> */}
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}
export default Login

const emailValidationSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const phoneValidationSchema = yup.object().shape({
  email: yup.string().required('Phone number is required'),
});