import React from 'react';

function Twitter() {
  return (
    <>
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm4.461 7.711c.004.1.007.2.007.3 0 3.04-2.315 6.547-6.55 6.547-1.3 0-2.51-.38-3.527-1.033a4.62 4.62 0 0 0 3.408-.953 2.304 2.304 0 0 1-2.15-1.6 2.32 2.32 0 0 0 1.038-.038 2.302 2.302 0 0 1-1.846-2.257v-.029a2.3 2.3 0 0 0 1.043.288 2.3 2.3 0 0 1-.712-3.073 6.535 6.535 0 0 0 4.743 2.405 2.302 2.302 0 0 1 3.922-2.1A4.594 4.594 0 0 0 15.3 5.61a2.312 2.312 0 0 1-1.014 1.274c.466-.056.911-.18 1.322-.363a4.63 4.63 0 0 1-1.147 1.19z"
          fill="#BEBEBE"
        />
      </svg>
    </>
  );
}
export default Twitter;
