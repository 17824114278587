import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios, { apiBaseUrl } from '../../services/axios';

const TopPlaces = () => {
  const [topPlaces, setTopPlaces] = useState([]);
  const getTopPlaces = async () => {
    const { data, status } = await axios.get(
      `Location/list?page_size=6&page=0`,
    );
    if (status === 200 || status === 201) {
      setTopPlaces(data?.results);
    }
  };
  useEffect(() => {
    getTopPlaces();
  }, []);

  return (
    <>
      {topPlaces && (
        <div className="app-component-top-places">
          <Grid container spacing={4}>
            {topPlaces &&
              topPlaces.map((topPlace, index) => (
                <Grid
                  key={index}
                  item
                  xs={index === 0 ? 6 : index === 3 ? 4 : index === 4 ? 5 : 3}
                >
                  <Link
                    to={`/properties-listing?locationId=${topPlace._id}&locationTitle=${topPlace.title}`}
                  >
                    <div className="app-component-top-places-card">
                      <img src={topPlace.icon ? (topPlace.icon.includes("amazonaws.com") ? topPlace.icon : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png") : "https://hotelmanagementbckt.s3.ap-south-1.amazonaws.com/default-placeholder.png"} />
                      <div className="app-component-top-places-card-content">
                        <h3>{topPlace.title}</h3>
                        <p>{topPlace.description}</p>
                        <h6>{`${topPlace.HotelsCount} Properties`}</h6>
                      </div>
                    </div>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </>
  );
};
export default TopPlaces;
