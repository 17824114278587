import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Grid, TextareaAutosize } from '@mui/material'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { Container } from '@mui/system'
import BookingDetailsResume from '../components/BookingDetailsResume'
import TopHeader from '../components/layouts/TopHeader'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLoggedUserData } from '../services/auth'
import axios from '../services/axios'
import { bookingUserDetailsSchema } from '../services/validations'
// import { loadScript } from 'utilities/LoadScript';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import CheckoutForm from 'components/CheckoutForm';

const stripePromise = loadStripe('pk_test_51NZv8WSIeoNx7WRJosdRkVFhQUT5Sq9MQDCCBE18EBjBKlvvB1dKTii7piZgURdPRpDjrERiBl8LlMcTyxvkyNPP00FEcESSuD')

const BookingProperty = () => {
	const navigate = useNavigate()
	const { bookingId } = useParams()
	const [anchorEl, setAnchorEl] = useState(null)
	const [isEditingDetails, setIsEditingDetails] = useState(true)
	const [loading, setLoading] = useState(true)
	const open = Boolean(anchorEl)
	const [property, setProperty] = useState(null)
	const [profileInfo, setProfileInfo] = useState(null)
	const [bookingInfo, setBookingInfo] = useState(null)
	const [continueLoading, setContinueLoading] = useState(false)
	const [sessionLoading, setSessionLoading] = useState(false)

	const token = localStorage.getItem("token")

	const createStripeSession = async () => {
		setSessionLoading(true)
		const { data, status } = await axios.post("/Payments/create-checkout", { bookingid: bookingId })

		window.location = data.session.url
	}

	const getProfile = async () => {
		const loggedUserData = await getLoggedUserData()
		const { data, status } = await axios.get(
			`Author/view/${loggedUserData._id}`,
		)
		if (status === 200 || status === 201) {
			setProfileInfo(data)
		}
	}
	useEffect(() => {
		getProfile()
	}, [])

	const getBooking = async () => {
		setLoading(true)
		const { data, status } = await axios.get(`/Booking/view/${bookingId}`)
		if (status === 200 || status === 201) {
			setBookingInfo(data)
		}
	}
	useEffect(() => {
		getBooking()
	}, [])

	const getProperty = async () => {
		const { data, status } = await axios.get(
			`Hotel/view/${bookingInfo.hotel_id}`,
		)
		if (status === 200 || status === 201) {
			setProperty(data)
			setLoading(false)
		}
	}

	useEffect(() => {
		if (bookingInfo) {
			getProperty()
		}
	}, [bookingInfo])

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(bookingUserDetailsSchema),
	})

	const onSubmit = async formData => {
		if (continueLoading) {
			return
		}

		setContinueLoading(true)

		const bookingUpdate = {
			author_name: formData.first_name,
			author_email: formData.email,
			author_phonenumber: formData.phonenumber,
			instructions: formData.instructions,
		}

		const { data, status } = await axios.patch(`/Booking/update/${bookingId}`, bookingUpdate)

		if (status === 200 || status === 201) {
			setIsEditingDetails(false)
			getBooking()
		}

		setContinueLoading(false)
	}

	const handleEditInfo = () => {
		setIsEditingDetails(true)
	}

	const handlePayNow = async () => {
		createStripeSession()
	}

	const handlePayAtHotel = async () => {
		setSessionLoading(true)

		const { data, status }= await axios.post("/Booking/pay-at-hotel", { token, bookingid: bookingId })

		if (data.success) {
			navigate("/thankyou/confirmed")
		}
		else {
			toast.error(data.message)
		}

		setSessionLoading(false)
	}

	return (
		<>
			<div className="app-page-booking-property">
				<div className="app-page-booking-property-header">
					<TopHeader />
				</div>
				{loading && (
					<div className="app-loading">
						<CircularProgress color="inherit" />
					</div>
				)}
				{!loading && (
					<div className="app-page-booking-property-content">
						<Container maxWidth="lg">
							<Grid container spacing={4}>
								{profileInfo && (
									<Grid item xs={7}>
										{!isEditingDetails && (
											<>
												<div className="app-page-payment-person-details">
													<div className="app-page-payment-person-detail-card">
														<h3>Your details</h3>
														<ul>
															<li>
																<p>Name</p>
																<span>
																	{bookingInfo.author_name
																		? bookingInfo.author_name
																		: profileInfo?.first_name}
																</span>
															</li>
															<li>
																<p>Email</p>
																<span className="scrollbar-styling"style={{ width: "180px", overflowY: "scroll"}}>
																	{bookingInfo.author_email
																		? bookingInfo.author_email
																		: profileInfo?.email}
																</span>
															</li>
															<li>
																<p>Phone</p>
																<span>
																	{bookingInfo.author_phonenumber
																		? bookingInfo.author_phonenumber
																		: profileInfo?.phonenumber}
																</span>
															</li>
														</ul>
														<ul>
															<li>
																<p>Instructions</p>
																<span>{bookingInfo.instructions}</span>
															</li>
														</ul>
														<div className="payment-person-detail-card-edit">
															<Button color="primary" onClick={handleEditInfo}>
																Edit
															</Button>
														</div>
														<div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
															<Button
																style={{ fontSize: "13px", backgroundColor: sessionLoading ? "grey" : undefined }}
																className="app-custom-button"
																color={sessionLoading ? undefined : "primary"}
																variant="contained"
																onClick={sessionLoading ? undefined : () => handlePayAtHotel()}
															>
																Pay at Hotel
															</Button>
															<Button
																style={{ fontSize: "13px", backgroundColor: sessionLoading ? "grey" : "rgb(78, 156, 78)" }}
																className="app-custom-button"
																color={sessionLoading ? undefined : "primary"}
																variant="contained"
																onClick={sessionLoading ? undefined : () => handlePayNow()}
															>
																Confirm Payment
															</Button>
														</div>
													</div>
												</div>
											</>
										)}
										{isEditingDetails && (
											<div className="app-page-booking-property-wrapper">
												<div className="app-page-booking-property-form">
													<h3>Enter your details</h3>
													<form
														onSubmit={handleSubmit(onSubmit)}
														className="app-page-booking-property-form-group"
													>
														<Grid container spacing={4}>
															<Grid item xs={6}>
																<div className="field-wrapper">
																	<div className="app-page-booking-property-form-field">
																		<input
																			type={'text'}
																			placeholder="Name"
																			{...register('first_name', {
																				value: bookingInfo.author_name
																					? bookingInfo.author_name
																					: profileInfo?.first_name,
																			})}
																		/>
																		<img
																			className="booking-property-form-field-icon"
																			src="/assets/images/user.svg"
																		/>
																	</div>
																	{errors.first_name && (
																		<div className="error-message">
																			{errors.first_name.message}
																		</div>
																	)}
																</div>
															</Grid>
															<Grid item xs={6}>
																<div className="field-wrapper">
																	<div className="app-page-booking-property-form-field">
																		<input
																			type={'email'}
																			placeholder="Email"
																			{...register('email', {
																				value: bookingInfo.author_email
																					? bookingInfo.author_email
																					: profileInfo?.email,
																			})}
																		/>
																		<img
																			className="booking-property-form-field-icon"
																			src="/assets/images/open-mail.svg"
																		/>
																		<img
																			className="booking-property-form-field-indicator"
																			aria-owns={
																				open ? 'mouse-over-popover' : undefined
																			}
																			aria-haspopup="true"
																			onMouseEnter={handlePopoverOpen}
																			onMouseLeave={handlePopoverClose}
																			src="/assets/images/info.svg"
																		/>
																		<Popover
																			id="mouse-over-popover"
																			sx={{
																				pointerEvents: 'none',
																			}}
																			open={open}
																			anchorEl={anchorEl}
																			anchorOrigin={{
																				vertical: 'center',
																				horizontal: 'center',
																			}}
																			transformOrigin={{
																				vertical: 'top',
																				horizontal: 'left',
																			}}
																			onClose={handlePopoverClose}
																			disableRestoreFocus
																		>
																			<h6 className="popover-text">
																				ex: username@domain.com
																			</h6>
																		</Popover>
																	</div>
																	{errors.email && (
																		<div className="error-message">
																			{errors.email.message}
																		</div>
																	)}
																</div>
															</Grid>
															<Grid item xs={6}>
																<div className="field-wrapper">
																	<div className="app-page-booking-property-form-field">
																		<input
																			type={'number'}
																			placeholder="Phone"
																			{...register('phonenumber', {
																				value: bookingInfo.author_phonenumber
																					? bookingInfo.author_phonenumber
																					: profileInfo?.phonenumber,
																			})}
																		/>
																		<img
																			className="booking-property-form-field-icon"
																			src="/assets/images/telephone.svg"
																		/>
																	</div>
																	{errors.phonenumber && (
																		<div className="error-message">
																			{errors.phonenumber.message}
																		</div>
																	)}
																</div>
															</Grid>
															<Grid item xs={12}>
																<div className="field-wrapper">
																	<div className="app-page-booking-property-form-field">
																		<TextareaAutosize
																			className="app-page-booking-property-form-field"
																			aria-label="Instructions"
																			minRows={3}
																			{...register('instructions', {
																				value: bookingInfo.instructions,
																			})}
																			placeholder="Add a note"
																			style={{
																				width: '100%',
																				maxWidth: '100%',
																			}}
																		/>
																	</div>
																	{errors.instructions && (
																		<div className="error-message">
																			{errors.instructions.message}
																		</div>
																	)}
																</div>
															</Grid>
															<Grid item xs={12}>
																<div style={{ paddingBottom: "60px" }} className="booking-price-details-button">
																	<Button
																		style={{ marginTop: "-20px", backgroundColor: continueLoading ? "grey" : undefined }}
																		className="app-custom-button"
																		color={continueLoading ? undefined : "primary"}
																		variant="contained"
																		type="submit"
																	>
																		Continue
																	</Button>
																</div>
															</Grid>
														</Grid>
													</form>
												</div>
											</div>
										)}
										{/* <div className="app-page-booking-property-disabled-details">
                      <div className="app-page-booking-property-form">
                        <h3>Complete Booking</h3>
                        <div className="booking-price-details-button">
                          <Button
                            className="app-custom-button"
                            color="primary"
                            variant="contained"
                            type="submit"
                          >
                            Continue
                          </Button>
                        </div>
                      </div>
                    </div> */}
									</Grid>
								)}
								<Grid item xs={5}>
									<div className="app-page-booking-property-bookingdetails">
										<BookingDetailsResume
											bookingInfo={bookingInfo}
											property={property}
										/>
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>
				)}
			</div>
		</>
	)
}
export default BookingProperty
