import { Container, Typography } from "@mui/material"
import Banner from "../components/home/Banner"
import TopHeader from "../components/layouts/TopHeader"
import Footer from "../components/layouts/Footer"

const tabelOfContent = (
    <ol style={{display:'flex', flexDirection:'column', gap:'20px'}}>
        <li>Scope of Services</li>
        <li>Eligibility to use</li>
        <li>Using the 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform: Account Registration and use</li>
        <li>24Hours CheckIn (TRIPIZI Trot Private Limited) Services</li>
        <li>Term & Termination</li>
        <li>Terms specifically applicable to Customer</li>
        <li>Usage terms</li>
        <li>Prohibited Content</li>
        <li>Communications</li>
        <li>Third Party Links</li>
        <li>Insurance</li>
        <li>Intellectual Property</li>
        <li>Privacy</li>
        <li>Indemnification</li>
        <li>No warranty</li>
        <li>Limitation of Liability</li>
        <li>Refund Claim Period</li>
        <li>Modification of these Terms</li>
        <li>General Provisions</li>
    </ol>
)
const scopeOfServise = (
    <ol type="a" style={{display:'flex', flexDirection:'column', gap:'20px'}}>
        <li>24Hours CheckIn (TRIPIZI Trot Private Limited) Rooms</li>
        <li>24Hours CheckIn (TRIPIZI Trot Private Limited) Homes</li>
        <li>24Hours CheckIn (TRIPIZI Trot Private Limited) Townhouse</li>
        <li>24Hours CheckIn (TRIPIZI Trot Private Limited) Business</li>
        <li>24Hours CheckIn (TRIPIZI Trot Private Limited) Flagship</li>
        <li>24Hours CheckIn (TRIPIZI Trot Private Limited) Silverkey</li>
    </ol>
)

const TermsandConditions = () => {
    return (
        <>
            <div className="app-home-wrapper" style={{position:'relative', height:'100vh'}}>
				<div className="app-home-header">
					<TopHeader />
				</div>
                <div style={{width:'82%', backgroundColor:'white', position:'absolute', zIndex:10, top:'20%', left:'8%', boxShadow:' 0 0 10px', borderRadius:'10px'  }}>
                    <div style={{margin:'60px 80px'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'30px'}}>
                            <Typography variant="h4" fontWeight={600}>
                                Terms of use
                            </Typography>
                            <Typography variant='body2'>Last updated: 08/02/2023</Typography>
                        </div>
                        <div>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>These terms form a legally binding agreement between Users (“User(s), you, your, customer(s), yourself”) and Oravel Stays Private Limited and/or its subsidiaries and affiliates (“24Hours CheckIn (TRIPIZI Trot Private Limited), we, us, our”). These terms shall govern the use of the website, mobile application, call centers (collectively referred to as “24Hours CheckIn (TRIPIZI Trot Private Limited) Platform”) which enables the User to connect with us in relation to the services offered through the 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform ("Services").</p>
                                <p>Please read these terms carefully before accessing, using, obtaining or availing any products or Services by 24Hours CheckIn (TRIPIZI Trot Private Limited). If you do not agree with these Terms of Use you may refrain from using the 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform and/or Services. These conditions must be read in conjunction with any other applicable terms and conditions governing the use of 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform and Services.</p>
                                <p>Please read these terms carefully before accessing, using, obtaining or availing any products or Services by 24Hours CheckIn (TRIPIZI Trot Private Limited). If you do not agree with these Terms of Use you may refrain from using the 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform and/or Services. These conditions must be read in conjunction with any other applicable terms and conditions governing the use of 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform and Services.</p>
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                            <Typography variant="h6" fontWeight={600}>Table of Content</Typography>
                            <Typography variant="body1" fontSize={'17px'}>
                                {tabelOfContent}
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                            <Typography variant="h6" fontWeight={600}>1. Scope of Services</Typography>
                            <Typography variant="body1" fontSize={'17px'}>
                                <p>24Hours CheckIn (TRIPIZI Trot Private Limited) through the 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform markets. 24Hours CheckIn (TRIPIZI Trot Private Limited) branded properties, managed and/or owned by third parties (“Channel Partners”) as per agreed terms with 24Hours CheckIn (TRIPIZI Trot Private Limited) or 24Hours CheckIn (TRIPIZI Trot Private Limited) as the case maybe to help the Users avail the stay Services right (24Hours CheckIn (TRIPIZI Trot Private Limited)’s Service). 24Hours CheckIn (TRIPIZI Trot Private Limited) Platform offers Services to its Users by offering its products (“24Hours CheckIn (TRIPIZI Trot Private Limited) Products”) which shall include but shall not be limited to:</p>
                                {scopeOfServise}
                            </Typography>
                            <hr style={{margin:'40px 0px'}}/>
                        </div>
                    </div>
                </div>
				<div className="app-home-banner" style={{marginBottom:'160px'}}>
                    <div className="app-component-banner app-component-banner-wrapper">
                    </div>
				</div>
                {/* <Footer /> */}
            </div>
        </>
    )
}

export default TermsandConditions