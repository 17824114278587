import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import DatePicker, {
  utils,
} from '@hassanmojab/react-modern-calendar-datepicker';
import { useEffect, useState } from 'react';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import axios from '../../services/axios';
import {
  formatDateDDDMMM,
  stringDateToRangeObject,
} from '../../utilities/DateFormat';

const DEFAULT_OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptionsLimited = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, DEFAULT_OPTIONS_LIMIT);
};

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state);
};

const prepareApiSearchParams = (
  selectedLocation,
  selectedArea,
  selectedDayRange,
  adultsNumber,
  childrensNumber,
  roomsNumber,
) => {
  const apiSearchParams = new URLSearchParams();
  apiSearchParams.append('locationId', selectedLocation.id);
  apiSearchParams.append('locationTitle', selectedLocation.title);
  apiSearchParams.append('area_id', selectedArea.id);
  apiSearchParams.append('areaTitle', selectedArea.title);
  apiSearchParams.append(
    'from',
    selectedDayRange.from
      ? `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`
      : '',
  );
  apiSearchParams.append(
    'to',
    selectedDayRange.to
      ? `${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
      : '',
  );

  console.log({roomsNumber, selectedArea})

  apiSearchParams.append('adults', adultsNumber.toString());
  apiSearchParams.append('childrens', childrensNumber.toString());
  apiSearchParams.append('rooms', roomsNumber.toString());
  return apiSearchParams;
};

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorE, setAnchorE] = React.useState(null);
  const opened = Boolean(anchorE);

  const [searchingLocation, setSearchingLocation] = useState(false);
  const [searchingArea, setSearchingArea] = useState(false);
  const [locations, setLocations] = useState([{}]);
  const [areas, setAreas] = useState([{}]);

  const [selectedLocation, setSelectedLocation] = useState({
    id: searchParams.get('locationId') ?? '',
    title: searchParams.get('locationTitle') ?? '',
  });
  const [selectedArea, setSelectedArea] = useState({
    id: searchParams.get('areaId') ?? '',
    title: searchParams.get('areaTitle') ?? '',
  })
  const [adultsNumber, setAdultsNumber] = useState(
    searchParams.get('adults') ? Number(searchParams.get('adults')) : 1,
  );
  const [childrensNumber, setChildrensNumber] = useState(
    searchParams.get('childrens') ? Number(searchParams.get('childrens')) : 0,
  );
  const [roomsNumber, setRoomsNumber] = useState(
    searchParams.get('rooms') ? Number(searchParams.get('rooms')) : 1,
  );
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: searchParams.get('from')
      ? stringDateToRangeObject(searchParams.get('from'))
      : null,
    to: searchParams.get('to')
      ? stringDateToRangeObject(searchParams.get('to'))
      : null,
  });

  const getLocations = async () => {
    const { data, status } = await axios.get(`Location/list`);
    if (status === 200 || status === 201) {
      setLocations(data?.results);
    }
  };
  useEffect(() => {
    getLocations();
  }, []);

  const getAreas = async() => {
    const { data, status } = await axios.get(`Areas/list`);
    if (status === 200 || status === 201) {
      setAreas(data?.results);
    }
  };
  useEffect(() => {
    getAreas();
  }, []);

  const handleClick = event => {
    setAnchorE(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE(null);
  };

  const formatInputValue = () => {
    if (!selectedDayRange.from || !selectedDayRange.to) return '';
    return `${formatDateDDDMMM(selectedDayRange.from)} - ${formatDateDDDMMM(
      selectedDayRange.to,
    )}`;
  };

  const onSubmit = event => {
    event.preventDefault();

    const formattedRange = JSON.parse(JSON.stringify(selectedDayRange))

    formattedRange.from.month = String(formattedRange.from.month).padStart(2, "0")
    formattedRange.to.month = String(formattedRange.to.month).padStart(2, "0")

    const apiSearchParams = prepareApiSearchParams(
      selectedLocation,
      selectedArea,
      formattedRange,
      adultsNumber,
      childrensNumber,
      roomsNumber,
    );

    if (location.pathname === '/home') {
      navigate(`/properties-listing?${apiSearchParams}`);
    } else {
      setSearchParams(apiSearchParams);
    }
  };

  const locationsDropDown = locations.map(option => ({
    id: option._id,
    title: option.title,
  }));

  const areasDropDown = areas.filter(option => option.location_id === selectedLocation.id).map(option => ({
    id: option._id,
    title: option.title,
  }));

  return (
    <>
      <div className="app-component-search">
        <form onSubmit={onSubmit}>
          <ul>
            <li>
              <div className="search-component-form-group">
                <h4>Where you want to stay...</h4>
                <div className="search-component-form-field">
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    value={selectedLocation}
                    filterOptions={
                      searchingLocation ? filterOptions : filterOptionsLimited
                    }
                    options={locationsDropDown}
                    getOptionLabel={option => option.title}
                    onInputChange={(_event, value) => {
                      value !== ''
                        ? setSearchingLocation(true)
                        : setSearchingLocation(false);
                    }}
                    onChange={(_event, value) => {
                      setSelectedLocation({
                        id: value?.id ?? '',
                        title: value?.title ?? '',
                      });
                      setSelectedArea({
                        id: '',
                        title: '',
                      })
                    }}
                    renderInput={params => (
                      <TextField
                        placeholder="Search your location..."
                        {...params}
                      />
                    )}
                  />
                  <img
                    className="form-field-icon-append"
                    src="/assets/images/search-icon.svg"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="search-component-form-group">
                <h4>where in {selectedLocation?.title}</h4>
                <div className="search-component-form-field">
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    value={selectedArea}
                    filterOptions={
                      searchingArea ? filterOptions : filterOptionsLimited
                    }
                    options={areasDropDown}
                    getOptionLabel={option => option.title}
                    onInputChange={(_event, value) => {
                      value !== ''
                        ? setSearchingArea(true)
                        : setSearchingArea(false);
                    }}
                    onChange={(_event, value) => {
                      setSelectedArea({
                        id: value?.id ?? '',
                        title: value?.title ?? '',
                      });
                    }}
                    renderInput={params => (
                      <TextField
                        placeholder="Search Local..."
                        {...params}
                      />
                    )}
                  />
                  <img
                    className="form-field-icon-append"
                    src="/assets/images/search-icon.svg"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="search-component-form-group">
                <h4>Select date</h4>
                <div className="search-component-form-field">
                  <DatePicker
                    value={selectedDayRange}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="Select a range"
                    formatInputText={formatInputValue}
                    inputClassName="datepicker-range"
                    shouldHighlightWeekends
                    colorPrimary="#ff6431"
                    colorPrimaryLight="#ffeae4"
                    minimumDate={utils('en').getToday()}
                  />
                  <img
                    className="form-field-icon-append"
                    src="/assets/images/calander-icon.svg"
                    style={{zIndex:"10"}}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="search-component-form-group">
                <h4>Select no of Guest…</h4>
                <div className="search-component-form-field">
                  <Button
                    type="button"
                    className="search-component-guest-select"
                    id="basic-button"
                    aria-controls={opened ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={opened ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <span className="guest-select-text">
                      {`${adultsNumber} A, ${childrensNumber} C, ${roomsNumber} ${
                        roomsNumber > 1 ? 'Rooms' : 'Room'
                      }`}
                    </span>
                  </Button>
                  <Menu
                    className="search-component-guest-select-menu"
                    id="basic-menu"
                    anchorEl={anchorE}
                    open={opened}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <div className="search-component-guest-select-menu-wrapper">
                      <h4>Popular Searches</h4>
                      <ul className="guest-select-options">
                        <li className="guest-select-option-item">
                          <div className="guest-select-option-content">
                            <h6>No of Guests</h6>
                            <p>Adults 18+ years</p>
                          </div>
                          <div className="guest-select-option-increment">
                            <div className="guest-select-option-button guest-select-option-button-left">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setAdultsNumber(oldValue => {
                                    return oldValue > 0 ? oldValue - 1 : 0;
                                  });
                                }}
                              >
                              </Button>
                            </div>
                            <div className="guest-select-option-input">
                              <input
                                type={'text'}
                                value={adultsNumber}
                                readOnly
                              />
                            </div>
                            <div className="guest-select-option-button guest-select-option-button-right">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setAdultsNumber(oldValue => {
                                    return oldValue + 1;
                                  });
                                }}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </li>
                        <li className="guest-select-option-item">
                          <div className="guest-select-option-content">
                            <h6>Clildrens</h6>
                            <p>From 0 to 17 years</p>
                          </div>
                          <div className="guest-select-option-increment">
                            <div className="guest-select-option-button guest-select-option-button-left">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setChildrensNumber(oldValue => {
                                    return oldValue > 0 ? oldValue - 1 : 0;
                                  });
                                }}
                              >
                                -
                              </Button>
                            </div>
                            <div className="guest-select-option-input">
                              <input
                                type={'text'}
                                value={childrensNumber}
                                readOnly
                              />
                            </div>
                            <div className="guest-select-option-button guest-select-option-button-right">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setChildrensNumber(oldValue => {
                                    return oldValue + 1;
                                  });
                                }}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </li>
                        <li className="guest-select-option-item">
                          <div className="guest-select-option-content">
                            <h6>No of rooms</h6>
                          </div>
                          <div className="guest-select-option-increment">
                            <div className="guest-select-option-button guest-select-option-button-left">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setRoomsNumber(oldValue => {
                                    return oldValue > 0 ? oldValue - 1 : 0;
                                  });
                                }}
                              >
                                -
                              </Button>
                            </div>
                            <div className="guest-select-option-input">
                              <input
                                type={'text'}
                                value={roomsNumber}
                                readOnly
                              />
                            </div>
                            <div className="guest-select-option-button guest-select-option-button-right">
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setRoomsNumber(oldValue => {
                                    return oldValue + 1;
                                  });
                                }}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Menu>
                  <img
                    className="form-field-icon-append"
                    src="/assets/images/people-icon.svg"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="search-component-frm-group">
                <div className="search-component-form-button">
                  <Button type="submit" color="primary" variant="contained">
                    Search
                  </Button>
                </div>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </>
  );
};
export default Search;
