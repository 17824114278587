import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Slider from '@mui/material/Slider'
import * as React from 'react'
import { useEffect, useState } from 'react'
import axios from '../services/axios'

export const MIN_PRICE_RANGE = 10
export const MAX_PRICE_RANGE = 125000

const Filter = ({ filtering, setFiltering, setSearchParams, resetPage }) => {
	const fromPrice = Number(filtering.fromPrice)
	const toPrice = Number(filtering.toPrice)

	const [value, setValue] = useState([fromPrice, toPrice])
	const [services, setServices] = useState([])
	const [propertyTypes, setPropertyTypes] = useState([])
	const [amenities, setAmenities] = useState([])

	const getServices = async () => {
		const { data, status } = await axios.get(`Services/list`)
		if (status === 200 || status === 201) {
			setServices(data?.results)
		}
	}
	const getPropertyTypes = async () => {
		const { data, status } = await axios.get(`PropertyType/list`)
		if (status === 200 || status === 201) {
			setPropertyTypes(data?.results)
		}
	}
	const getAmenities = async () => {
		const { data, status } = await axios.get(`Amenties/list`)
		if (status === 200 || status === 201) {
			setAmenities(data?.results)
		}
	}

	useEffect(() => {
		getServices()
		getPropertyTypes()
		getAmenities()
	}, [])

	const handlePriceRangeChange = (_event, newValue) => {
		setValue(newValue)
	}

	const handlePriceRangeChangeCommited = (_event, newValue) => {
		resetPage()
		setValue(newValue)
		setFiltering(oldFiltering => ({
			...oldFiltering,
			fromPrice: newValue[0],
			toPrice: newValue[1],
		}))
		setSearchParams(searchParams => {
			searchParams.set('fromPrime', newValue[0])
			searchParams.set('toPrice', newValue[1])
			return searchParams
		})
	}

	const handleServiceFilterChange = event => {
		resetPage()
		let newFilterServices = []
		setFiltering(prev => {
			if (event.target.checked) {
				const serviceExist = prev.services.find(
					service => service === event.target.value,
				)
				if (!serviceExist) {
					newFilterServices = [...prev.services, event.target.value]
				}
			} else {
				newFilterServices = prev.services.filter(
					service => service !== event.target.value,
				)
			}
			return {
				...prev,
				services: newFilterServices,
			}
		})
		setSearchParams(searchParams => {
			if (newFilterServices.length === 0) {
				searchParams.delete('services')
			} else {
				searchParams.set('services', newFilterServices)
			}
			return searchParams
		})
	}

	console.log({ filtering })

	const handlePropertyTypesFilterChange = event => {
		resetPage()
		let newFilterPropertyTypes = []
		setFiltering(oldFiltering => {
			if (event.target.checked) {
				const propertyTypeExist = oldFiltering.propertyTypes.find(
					propertytype => propertytype === event.target.value,
				)
				if (!propertyTypeExist) {
					newFilterPropertyTypes = [
						...oldFiltering.propertyTypes,
						event.target.value,
					]
				}
			} else {
				newFilterPropertyTypes = oldFiltering.propertyTypes.filter(
					propertytype => propertytype !== event.target.value,
				)
			}

			console.log({ newFilterPropertyTypes })

			return {
				...oldFiltering,
				propertyTypes: newFilterPropertyTypes,
			}
		})
		setSearchParams(searchParams => {
			if (newFilterPropertyTypes.length === 0) {
				searchParams.delete('propertytypes')
			} else {
				searchParams.set('propertytypes', newFilterPropertyTypes)
			}
			return searchParams
		})
	}

	const handleAmenitiesFilterChange = event => {
		resetPage()
		let newFilterAmenities = []
		setFiltering(oldFiltering => {
			if (event.target.checked) {
				const amenityExist = oldFiltering.amenities.find(
					amenity => amenity === event.target.value,
				)
				if (!amenityExist) {
					newFilterAmenities = [...oldFiltering.amenities, event.target.value]
				}
			} else {
				newFilterAmenities = oldFiltering.amenities.filter(
					amenity => amenity !== event.target.value,
				)
			}
			return {
				...oldFiltering,
				amenities: newFilterAmenities,
			}
		})
		setSearchParams(searchParams => {
			if (newFilterAmenities.length === 0) {
				searchParams.delete('amenities')
			} else {
				searchParams.set('amenities', newFilterAmenities)
			}
			return searchParams
		})
	}

	const isServiceChecked = serviceId => {
		let checked = false
		if (filtering.services) {
			const serviceInFiltering = filtering.services.find(
				service => service === serviceId,
			)
			if (serviceInFiltering) {
				checked = true
			}
		}
		return checked
	}

	const isPropertyTypeChecked = propertyTypeId => {
		let checked = false
		if (filtering.propertyTypes) {
			const propertyTypeInFiltering = filtering.propertyTypes.find(
				propertytype => propertytype === propertyTypeId,
			)
			if (propertyTypeInFiltering) {
				checked = true
			}
		}
		return checked
	}

	const isAmenityChecked = amenityId => {
		let checked = false
		if (filtering.amenities) {
			const amenityInFiltering = filtering.amenities.find(
				amenity => amenity === amenityId,
			)
			if (amenityInFiltering) {
				checked = true
			}
		}
		return checked
	}

	return (
		<>
			<div className="app-component-filter">
				<ul className="app-component-filter-list">
					<li className="app-component-filter-list-item">
						<div className="app-component-filter-wrapper">
							<div className="app-component-filter-heading">
								<h3>Filters</h3>
							</div>
							<div className="app-component-filter-price-range">
								<h4>Price Range</h4>
								<h3>₹ {fromPrice} - ₹ {toPrice}</h3>
								<div className="app-component-filter-price-slider">
									<p>Starting from ₹ 1000</p>
									<div className="app-component-filter-price-slider-content">
										<Slider
											getAriaLabel={() => 'Price range'}
											value={value}
											onChange={handlePriceRangeChange}
											onChangeCommitted={handlePriceRangeChangeCommited}
											valueLabelDisplay="auto"
											min={MIN_PRICE_RANGE}
											max={MAX_PRICE_RANGE}
											step={100}
											disableSwap
										/>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li className="app-component-filter-list-item">
						<div className="app-component-filter-check">
							<div className="app-component-filter-check-heading">
								<h6>Services</h6>
								<Button>Clear</Button>
							</div>
							<ul className="app-component-filter-check-list">
								{services.map(service => (
									<li
										key={service._id}
										className="app-component-filter-check-list-item"
									>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={isServiceChecked(service._id)}
													name={service.title}
													value={service._id}
													onChange={handleServiceFilterChange}
												/>
											}
											label={service.title}
										/>
									</li>
								))}
							</ul>
						</div>
					</li>
					<li className="app-component-filter-list-item">
						<div className="app-component-filter-check">
							<div className="app-component-filter-check-heading">
								<h6>Property Types</h6>
								<Button>Clear</Button>
							</div>
							<ul className="app-component-filter-check-list">
								{propertyTypes.map(propertyType => (
									<li
										key={propertyType._id}
										className="app-component-filter-check-list-item"
									>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={isPropertyTypeChecked(propertyType._id)}
													name={propertyType.title}
													value={propertyType._id}
													onChange={handlePropertyTypesFilterChange}
												/>
											}
											label={propertyType.title}
										/>
									</li>
								))}
							</ul>
						</div>
					</li>
					<li className="app-component-filter-list-item">
						<div className="app-component-filter-check">
							<div className="app-component-filter-check-heading">
								<h6>Amenities</h6>
								<Button>Clear</Button>
							</div>
							<ul className="app-component-filter-check-list">
								{amenities.map(amenity => (
									<li
										key={amenity._id}
										className="app-component-filter-check-list-item"
									>
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													checked={isAmenityChecked(amenity._id)}
													name={amenity.title}
													value={amenity._id}
													onChange={handleAmenitiesFilterChange}
												/>
											}
											label={amenity.title}
										/>
									</li>
								))}
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</>
	)
}
export default Filter
